import React, { useState } from 'react';
import './App.css';

function CounterPage() {
  const [count, setCount] = useState(0);
  const [targetNumber, setTargetNumber] = useState('');

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const calculatePercentage = () => {
    if (!targetNumber || isNaN(targetNumber) || targetNumber <= 0) {
      return '0%';
    }
    const percentage = (count / parseInt(targetNumber)) * 100;
    return `${percentage.toFixed(1)}%`;
  };

  return (
    <div style={{ textAlign: 'center' }}>

    <div class="card card--4" style={{position:'absolute', /*top:'400vh'*/}}>
        <div class="relative flex flex-col h-[100vh] items-center justify-center transition-bg">
            <div class="absolute inset-0 overflow-hidden">

                <div className="counter-section">
                    <h2>Current Count: {count}</h2>
                <button 
                    className="gradient-button"
                    onClick={handleIncrement}
                    style={{alignSelf:'center', justifySelf:'center'}}
                >
                    Increment Counter
                </button>
                </div>

                <div className="input-section">
                <input
                    type="number"
                    value={targetNumber}
                    onChange={(e) => setTargetNumber(e.target.value)}
                    placeholder="Enter target number"
                    className="input-field"
                />
                </div>

                <div className="percentage-section">
                    <h2>Progress: {calculatePercentage()}</h2>
                    <div className="progress-bar-container">
                        <div 
                        className="progress-bar"
                        style={{
                            width: calculatePercentage(),
                            backgroundColor: '#4CAF50',
                            transition: 'width 0.3s ease-in-out'
                        }}
                        ></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
  );
}

export default CounterPage;