import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import FeedbackButton from './FeedbackButton';
import { Video, Play, Pause, RefreshCw, Mic, Square, Trash2, Check, AlertCircle, Loader, ExternalLink, User, Briefcase, Award, Activity, MessageSquare, Film, Eye, Settings, Download, ChevronUp, ChevronDown, Volume2, Music, Info, Send } from 'react-feather';
import api from './api';
import { decodeToken, isTokenExpired } from './utils/jwt';
import { trackEvent } from './utils/amplitude';
import debounce from 'lodash/debounce';
// import WaveSurfer from 'wavesurfer.js';
import './App.css';

// Recording Examples
const RECORDING_EXAMPLES = [
    // Introduction & Context
    "Hi, I noticed your impressive work at [Company] and wanted to reach out.",
    "I've been following [Company]'s growth in the [industry] space.",
    "Your recent post about [topic] really resonated with me.",
    "Your background in [field] caught my attention.",
    "I saw your team is expanding the [department/initiative] and thought I'd reach out.",

    // Value Proposition
    "We help companies like yours improve [metric] by [percentage].",
    "Our solution has helped similar businesses save [X] hours per week.",
    "We've helped other [job title]s increase their team's productivity by [percentage].",
    "Our platform typically reduces [pain point] by [metric].",
    "Companies similar to yours have seen [specific result] within [timeframe].",

    // Industry-Specific
    "The [industry] landscape is rapidly changing, especially regarding [trend].",
    "Many [job title]s we work with struggle with [common challenge].",
    "In today's [industry] environment, [pain point] is more crucial than ever.",
    "With recent changes in [regulation/technology], many companies are looking to [goal].",
    "We've noticed a growing trend in [industry] where [observation].",

    // Problem Recognition
    "Many teams face challenges with [common pain point].",
    "I understand how challenging [specific task] can be.",
    "One common issue we see is [problem description].",
    "Teams often struggle to balance [competing priority A] with [priority B].",
    "The complexity of [process] often leads to [negative outcome].",

    // Solution Overview
    "Our approach simplifies [complex process] through [method].",
    "We've developed a unique solution for [specific challenge].",
    "Our platform automates [manual process] while maintaining [important aspect].",
    "We combine [technology A] with [technology B] to achieve [outcome].",
    "Our solution integrates seamlessly with [common tools] to improve [process].",

    // Results & Benefits
    "Clients typically see ROI within [timeframe].",
    "Our average customer saves [amount] per month.",
    "Teams report [percentage] improvement in [metric] after implementation.",
    "We've helped companies reduce [cost/time] by up to [percentage].",
    "Users experience [benefit] while maintaining [important factor].",

    // Social Proof
    "Companies like [known brand] have achieved [result] using our solution.",
    "We recently helped a [industry] company overcome [challenge].",
    "One of our clients in [industry] reported [specific outcome].",
    "A similar [company type] saw [improvement] within [timeframe].",
    "We've worked with several teams in [industry] to improve [metric].",

    // Use Cases
    "This works particularly well for teams that need to [specific use case].",
    "It's especially useful when you're trying to [achieve goal].",
    "Many teams use this to streamline their [process].",
    "This solution is perfect for organizations looking to [objective].",
    "We've seen great results with teams focusing on [specific goal].",

    // Implementation & Support
    "The implementation process typically takes [timeframe].",
    "Our support team provides [type of support] throughout the process.",
    "We offer comprehensive training for [feature/process].",
    "The onboarding process is designed to minimize [common concern].",
    "Our team handles all aspects of [technical process].",

    // Future-Focused
    "We're constantly developing new features for [emerging need].",
    "Our roadmap includes exciting developments in [area].",
    "We're preparing for [industry trend] with [feature/capability].",
    "Our solution adapts to [changing circumstance] automatically.",
    "We're investing heavily in [future technology/capability]."
];

const PROSPECT_STATUSES = [
    { value: 'new', label: 'New Lead' },
    { value: 'video_sent', label: 'Video Sent' },
    { value: 'viewed', label: 'Video Viewed' },
    { value: 'responded', label: 'Responded' },
    { value: 'meeting_scheduled', label: 'Meeting Scheduled' },
    { value: 'negotiating', label: 'Negotiating' },
    { value: 'deal_closed', label: 'Deal Closed' },
    { value: 'lost', label: 'Lost Opportunity' }
];

function Dashboard() {

    // User info
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(null);

    // Profile info
    const [profileComplete, setProfileComplete] = useState(false);
    const [userProfile, setUserProfile] = useState({
        currentSituation: '',
        shortTermGoals: '',
        longTermGoals: ''
    });
    const [prospectingProfile, setProspectingProfile] = useState({
        currentRole: '',
        companyPurpose: '',
        targetAudience: ''
    });
    const [prospectingProfileSaveStatus, setProspectingProfileSaveStatus] = useState('');
    const [userInfo, setUserInfo] = useState({
        picture: '',
        name: ''
    });
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [hasVisited, setHasVisited] = useState(true);

    // Navigation (for redirecting to landing page)
    const navigate = useNavigate();

    // Video imports
    const [openingVideo, setOpeningVideo] = useState(null);
    const [closingVideo, setClosingVideo] = useState(null);
    const [openingVideoUrl, setOpeningVideoUrl] = useState('');
    const [closingVideoUrl, setClosingVideoUrl] = useState('');
    const [uploadError, setUploadError] = useState('');
    const [isDeletingOpeningOrClosingVideo, setIsDeletingOpeningOrClosingVideo] = useState(false);

    // Voice generation
    const [isRecording, setIsRecording] = useState(false);
    const [recordings, setRecordings] = useState([]);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [isGeneratingVoice, setIsGeneratingVoice] = useState(false);
    const [isTestingVoice, setIsTestingVoice] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [isPlayingVoice, setIsPlayingVoice] = useState(false);
    const audioRef = useRef(null);
    const [recordingDuration, setRecordingDuration] = useState(0);
    const [timerInterval, setTimerInterval] = useState(null);
    const [isSavingRecording, setIsSavingRecording] = useState(false)
    const [recordingLength, setRecordingLength] = useState({});

    // LinkedIn prospects states
    const [prospects, setProspects] = useState([]);
    // const [newProspectUrl, setNewProspectUrl] = useState('');
    // const [isScrapingProfile, setIsScrapingProfile] = useState(false);
    // const [processingUrls, setProcessingUrls] = useState(new Set());
    // const [scrapingError, setScrapingError] = useState('');
    const [isDeleting, setIsDeleting] = useState(new Set()); // Track deleting state

    // Pitch generation
    const [generatingPitch, setGeneratingPitch] = useState(new Set());
    const [savingPitch, setSavingPitch] = useState(new Set());

    // Speech generation
    const [generatingSpeech, setGeneratingSpeech] = useState(new Set());
    const [audioUrls, setAudioUrls] = useState({}); // Store audio URLs by prospect ID

    // Video generation
    const [generatingVideo, setGeneratingVideo] = useState(new Set());

    // Copy to clipboard
    const [copiedLinks, setCopiedLinks] = useState(new Set());

    // Waveform visualization states
    // const waveformRefs = useRef({});
    // const wavesurferInstances = useRef({});

    // Recording Examples
    const [showExamples, setShowExamples] = useState(false);
    const [currentExample, setCurrentExample] = useState(0);
    const [displayedExamples, setDisplayedExamples] = useState([]);

    // User voice
    const [userVoiceId, setUserVoiceId] = useState(null);
    const [testAudioUrl, setTestAudioUrl] = useState('');

    // const [linkedinScreenshot, setLinkedinScreenshot] = useState(null);
    const [isProcessingLinkedinScreenshot, setIsProcessingLinkedinScreenshot] = useState(false);
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [error, setError] = useState('');

    const ENABLE_VIDEO_UPLOAD = false;
    const [showBetaMessage, setShowBetaMessage] = useState(false);

    const [showRecordingTips, setShowRecordingTips] = useState(false);
    const [showVideoSendingTip, setShowVideoSendingTip] = useState(false);
    const [hasCreatedFirstVideo, setHasCreatedFirstVideo] = useState(false);

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState('');
    const [isBetaUser, setIsBetaUser] = useState(false);
    const [backendError, setBackendError] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState({});

    const linkedinUrlInputRef = useRef(null);

    // Fetch prospects
    const fetchUserProspects = useCallback(async () => {
        try {
            console.log('Fetching prospects for user:', userId);
            const response = await api.getLinkedInProspects();
            console.log('Prospects response:', response);
            
            if (response.data) {
                setProspects(response.data);

                // Create audio URLs map from prospects
                const audioMap = {};
                response.data.forEach(prospect => {
                    if (prospect.generatedSpeech) {
                        audioMap[prospect._id] = prospect.generatedSpeech;
                    }
                });
                setAudioUrls(audioMap);
            

                console.log('User prospects set:', response.data);
            } else {
                console.error('No data in response');
                setProspects([]);
                setAudioUrls({});
            }
        } catch (error) {
            console.error('Error fetching prospects:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
            }
            setProspects([]);
            setAudioUrls({});
        }
    }, [userId]);

    // Fetch user info on component mount
    useEffect(() => {
        const initializeUser = async () => {
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get('token');
            let token = tokenFromUrl || localStorage.getItem('token');

            console.log("params:", params);
            console.log("tokenFromUrl:", tokenFromUrl);
            console.log('Token from localStorage:', localStorage.getItem('token').substring(0, 10));
            console.log("token:", token.substring(0, 10));

            if (tokenFromUrl) {
                // Save the token to local storage
                localStorage.setItem('token', tokenFromUrl);
                console.log('Token saved to localStorage:', tokenFromUrl);
                
                // Clean up the URL to remove the token
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            // Validate the token
            if (!token || isTokenExpired(token)) {
                console.log('Token is invalid or expired. Redirecting to landing page.');
                navigate('/');
                return;
            }

            // Decode the token to get the user ID and fetch files
            const decoded = decodeToken(token);
            console.log('decoded Token:', decoded);
            console.log('decoded username:', decoded.name);
            console.log('decoded ID:', decoded.userId);

            if (decoded) {
                setUserId(decoded.userId); // Set the user ID
                setUserName(decoded.name); // Extract the name from the token
                setUserInfo(prev => ({ ...prev, name: decoded.name }));

                try {
                    setBackendError(''); // Clear any previous error

                    // Fetch profile
                    const profileResponse = await api.getUserProfile();
                    console.log('Profile response:', profileResponse.data);

                    if (profileResponse.data) {
                        setUserProfile({
                            ...profileResponse.data.profile,
                            hasCreatedFirstVideo: profileResponse.data.hasCreatedFirstVideo,
                            isBetaUser: profileResponse.data.isBetaUser,
                            isSubscribed: profileResponse.data.isSubscribed,
                            stripeCustomerId: profileResponse.data.stripeCustomerId,
                            subscriptionStatus: profileResponse.data.subscriptionStatus
                        });

                        setProspectingProfile(profileResponse.data.prospectingProfile || {
                            currentRole: '',
                            companyPurpose: '',
                            targetAudience: ''
                        });
                        setProfileComplete(profileResponse.data.profileComplete);
                        setHasVisited(profileResponse.data.hasVisited);
                        setUserInfo(prev => ({
                            ...prev,
                            picture: profileResponse.data.picture,
                            name: profileResponse.data.name || decoded.name
                        }));
                        
                        if (!profileResponse.data.hasVisited) {
                            setShowProfileModal(true);
                            await api.markVisited();
                        }

                        // Initialize recordings from user data
                        if (profileResponse.data.recordings && Array.isArray(profileResponse.data.recordings)) {
                            setRecordings(profileResponse.data.recordings);
                        }

                        // Initialize test audio URL from user data
                        setTestAudioUrl(profileResponse.data.testAudio);
                        console.log('Test audio URL:', profileResponse.data.testAudio);
                    }

                    // Fetch User's LinkedIn prospects
                    await fetchUserProspects();
                    // const prospectsResponse = await api.getLinkedInProspects();
                    // setProspects(prospectsResponse.data);
                } catch (error) {
                    console.error('Error initializing:', error);
                    setBackendError('Hey people, Boukar here, I was coding and did some shit, server down, will fix asap, sorry for that, thanks for using the app (help!)');
                    if (error.response?.status === 401) {
                        navigate('/');
                    }
                }
            } else {
                setBackendError('Hey people, Boukar here, I was coding and did some shit, server down, will fix asap, sorry for that, thanks for using the app (help!)');//setBackendError('Unable to load data, server down, please try again later');
            }
        };

        initializeUser();
    }, [navigate, fetchUserProspects]);

    // Handle profile updates
    // const handleProfileSave = async () => {
    //     try {
    //         trackEvent('profile_update_started', {
    //             userId,
    //             fieldsUpdated: Object.keys(userProfile).filter(key => userProfile[key].trim() !== '')
    //         });

    //         const response = await api.updateProfile(userProfile);

    //         trackEvent('profile_update_successful', {
    //             userId,
    //             profileComplete: response.data.profileComplete
    //         });

    //         setProfileComplete(response.data.profileComplete);
    //         setShowProfileModal(false);
    //     } catch (error) {
    //         trackEvent('profile_update_error', {
    //             error: error.message,
    //             userId
    //         });

    //         console.error('Error saving profile:', error);
    //     }
    // };

    useEffect(() => {
        // Check for successful subscription
        const queryParams = new URLSearchParams(window.location.search);
        const session_id = queryParams.get('session_id');
        
        if (session_id) {
            const verifySubscription = async () => {
                try {
                    const response = await api.get(`/api/subscription-status?session_id=${session_id}`);
                    if (response.data.isActive) {
                        // Update local state
                        setUserProfile(prev => ({
                            ...prev,
                            isSubscribed: true,
                            subscriptionStatus: 'active'
                        }));
                        
                        // Show success message
                        alert('🎉 Subscription activated successfully!');
                        
                        // Clean up URL
                        window.history.replaceState({}, document.title, window.location.pathname);
                    }
                } catch (error) {
                    console.error('Error verifying subscription:', error);
                }
            };
    
            verifySubscription();
        }
    }, []); // Run once on component mount
    
    // Add this useEffect to handle the keyboard shortcut
    useEffect(() => {
        const handleKeyPress = async (event) => {
            // Check for Cmd/Ctrl + K
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault(); // Prevent default browser behavior
                console.log('Beta mode:', isBetaUser);
                
                try {
                    console.log('Toggling beta mode...');
                    // Toggle beta status
                    const newBetaStatus = !userProfile.isBetaUser;
                    const response = await api.updateProfile({ isBetaUser: newBetaStatus });
                    console.log('Response:', response.data);
                    
                    if (response.data.isBetaUser !== undefined) {
                        setUserProfile(prev => ({ ...prev, isBetaUser: newBetaStatus }));
                        console.log('Updated userProfile:', userProfile);
                        
                        // Show appropriate message
                        alert(newBetaStatus 
                            ? 'Beta mode activated! You can now create videos without a subscription.' 
                            : 'Beta mode deactivated. Subscription required for video creation.'
                        );
                        console.log('Beta mode:', newBetaStatus);
                        
                        // Track event
                        trackEvent(newBetaStatus ? 'beta_mode_activated' : 'beta_mode_deactivated', {
                            userId,
                            method: 'keyboard_shortcut'
                        });
                    }
                } catch (error) {
                    console.error('Error toggling beta mode:', error);
                }
            }
        };
    
        // Add event listener
        window.addEventListener('keydown', handleKeyPress);
    
        // Cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [userId, isBetaUser]); // Added isBetaUser to dependencies

    // Handle prospecting profile updates
    const handleProspectingProfileSave = async () => {
        try {
            setProspectingProfileSaveStatus('saving');

            trackEvent('prospecting_profile_update_started', {
                userId,
                fieldsUpdated: Object.keys(prospectingProfile).filter(key => prospectingProfile[key].trim() !== '')
            });

            const response = await api.updateProspectingProfile(prospectingProfile);

            trackEvent('prospecting_profile_update_successful', {
                userId,
                prospectingProfileComplete: response.data.prospectingProfileComplete
            });

            setProspectingProfileSaveStatus('saved');

            // Reset the save status after 3 seconds
            setTimeout(() => {
                setProspectingProfileSaveStatus('');
            }, 2000);

            // You might want to show a success message here
        } catch (error) {
            trackEvent('prospecting_profile_update_error', {
                error: error.message,
                userId
            });

            console.error('Error saving prospecting profile:', error);
            setProspectingProfileSaveStatus('error');

            // Reset the save status after 3 seconds
            setTimeout(() => {
                setProspectingProfileSaveStatus('');
            }, 2000);
        }
    };

    // Profile Modal Component
    const ProfileModal = () => {
        // État local pour le formulaire
        const [formData, setFormData] = useState({
            currentSituation: userProfile.currentSituation || '',
            shortTermGoals: userProfile.shortTermGoals || '',
            longTermGoals: userProfile.longTermGoals || ''
        });

        const handleSave = async () => {
            try {
                setShowProfileModal(false);
                trackEvent('profile_update_started', {
                    userId,
                    fieldsUpdated: Object.keys(formData).filter(key => formData[key].trim() !== '')
                });

                // Structure the data correctly for the API
                const profileData = {
                    profile: {
                        currentSituation: formData.currentSituation,
                        shortTermGoals: formData.shortTermGoals,
                        longTermGoals: formData.longTermGoals
                    }
                };

                const response = await api.updateProfile(profileData);

                trackEvent('profile_update_successful', {
                    userId,
                    profileComplete: response.data.profileComplete
                });

                // Update userProfile state with the nested structure
                setUserProfile(prev => ({
                    ...prev,
                    ...response.data.profile
                }));
                setProfileComplete(response.data.profileComplete);
            } catch (error) {
                trackEvent('profile_update_error', {
                    error: error.message,
                    userId
                });
                console.error('Error saving profile:', error);
            }
        };

        const handleManageSubscription = async () => {
            try {
                const response = await api.createPortalSession();
                if (response.data.url) {
                    window.open(response.data.url, '_blank');
                }
            } catch (error) {
                console.error('Error opening subscription portal:', error);
                // Optionally show an error message to the user
            }
        };

        const handleLogout = () => {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            
            // Track logout event
            trackEvent('user_logged_out', { userId });
            
            // Redirect to home
            window.location.href = '/';
        };

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    backgroundColor: 'white',
                    padding: '30px',
                    borderRadius: '10px',
                    width: '90%',
                    maxWidth: '600px',
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }}>
                    <h2>Hi {(userInfo.name || userName)?.split(' ')[0]}!</h2>
                    <button
                        onClick={handleLogout}
                        style={{
                            padding: '8px 16px',
                            borderRadius: '5px',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #dfe1e5',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: '#8B0000' //'#dc3545'  // Red color for logout
                        }}
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                            <polyline points="16 17 21 12 16 7" />
                            <line x1="21" y1="12" x2="9" y2="12" />
                        </svg>
                        Logout
                    </button>
                    {userProfile.isSubscribed && (
                        <button
                            onClick={handleManageSubscription}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '5px',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #dfe1e5',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M3 10h18M7 15h.01M11 15h.01M15 15h.01M19 15h.01M5 20h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2z"/>
                            </svg>
                            Manage Subscription
                        </button>
                    )}
                    <h2>Tell us more about you</h2>
                    <p style={{ color: '#666', marginBottom: '20px' }}>
                        This information helps us provide better personalized assistance
                    </p>
                    {!hasVisited && (
                        <p style={{ color: '#007bff', marginBottom: '20px' }}>
                            You can change it whenever.
                        </p>
                    )}

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Current Situation
                        </label>
                        <textarea
                            value={formData.currentSituation}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                currentSituation: e.target.value
                            }))}
                            placeholder="Your current job, skills, experience..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Short Term Goals
                        </label>
                        <textarea
                            value={formData.shortTermGoals}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                shortTermGoals: e.target.value
                            }))}
                            placeholder="Goals for the next 3-6 months..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Long Term Goals
                        </label>
                        <textarea
                            value={formData.longTermGoals}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                longTermGoals: e.target.value
                            }))}
                            placeholder="Where do you see yourself in 1-5 years..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <button
                            onClick={() => setShowProfileModal(false)}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                backgroundColor: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            Close
                        </button>
                        <button
                            onClick={handleSave}
                            className="main-button"
                        >
                            Save Profile
                        </button>
                    </div>

                    
                </div>
            </div>
        );
    }

    // Opening video dropzone
    const onDropOpening = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.startsWith('video/')) {
            setOpeningVideo(file);
            
            try {
                console.log('Getting pre-signed URL for opening video...');
                // Step 1: Get pre-signed URL
                const { data: { uploadURL, key } } = await api.getUploadUrl(
                    file.type,
                    'opening'
                );
                console.log('Received pre-signed URL:', uploadURL);
                console.log('Key:', key);
    
                // Step 2: Upload directly to S3
                console.log('Starting S3 upload...');
                await api.uploadToS3(uploadURL, file);
                console.log('S3 upload complete');
    
                // Step 3: Confirm upload and update user record
                console.log('Confirming upload...');
                const response = await api.confirmUpload(key, 'opening');
                console.log('Upload confirmed:', response.data);
                
                if (response.data.url) {
                    setOpeningVideoUrl(response.data.url);
                    setUploadError('');
                    // Optionally show success message
                    console.log('Video URL updated successfully:', response.data.url);
                    
                } else {
                    throw new Error('No URL received from server');
                }
            } catch (error) {
                console.error('Error details:', {
                    message: error.message,
                    response: error.response?.data,
                    status: error.response?.status
                });
                setUploadError(`Failed to save opening video: ${error.message}`);
            }
        } else {
            setUploadError('Please upload a valid video file');
        }
    }, []);

    // Closing video dropzone
    const onDropClosing = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.startsWith('video/')) {
            setClosingVideo(file);
            
            try {
                console.log('Getting pre-signed URL for closing video...');
                // Step 1: Get pre-signed URL
                const { data: { uploadURL, key } } = await api.getUploadUrl(
                    file.type,
                    'closing'
                );
                console.log('Received pre-signed URL:', uploadURL);
                console.log('Key:', key);
    
                // Step 2: Upload directly to S3
                console.log('Starting S3 upload...');
                await api.uploadToS3(uploadURL, file);
                console.log('S3 upload complete');
    
                // Step 3: Confirm upload and update user record
                console.log('Confirming upload...');
                const response = await api.confirmUpload(key, 'closing');
                console.log('Upload confirmed:', response.data);
                
                if (response.data.url) {
                    setClosingVideoUrl(response.data.url);
                    setUploadError('');
                    // Optionally show success message
                    console.log('Video URL updated successfully:', response.data.url);
                    
                } else {
                    throw new Error('No URL received from server');
                }
            } catch (error) {
                console.error('Error details:', {
                    message: error.message,
                    response: error.response?.data,
                    status: error.response?.status
                });
                setUploadError(`Failed to save closing video: ${error.message}`);
            }
        } else {
            setUploadError('Please upload a valid video file');
        }
    }, []);

    // Configure dropzones
    const {
        getRootProps: getOpeningRootProps,
        getInputProps: getOpeningInputProps,
        isDragActive: isOpeningDragActive
    } = useDropzone({
        onDrop: onDropOpening,
        accept: {
            'video/*': ['.mp4', '.mov', '.avi', '.wmv']
        },
        maxFiles: 1
    });

    const {
        getRootProps: getClosingRootProps,
        getInputProps: getClosingInputProps,
        isDragActive: isClosingDragActive
    } = useDropzone({
        onDrop: onDropClosing,
        accept: {
            'video/*': ['.mp4', '.mov', '.avi', '.wmv']
        },
        maxFiles: 1
    });

    // Function to remove videos
    const removeVideo = async (category) => {
        try {
            setIsDeletingOpeningOrClosingVideo(true); // Add this state if you haven't already
            
            await api.deleteVideo(category);
            
            // Update local state
            if (category === 'opening') {
                setOpeningVideo(null);
                setOpeningVideoUrl(null);
            } else {
                setClosingVideo(null);
                setClosingVideoUrl(null);
            }
    
            // Optional: Show success message
            console.log(`${category} video deleted successfully`);
            trackEvent('video_deleted', {
                userId,
                category
            });
        } catch (error) {
            console.error('Error deleting video:', error);
            // Optional: Show error message to user
            setUploadError(`Failed to delete ${category} video: ${error.message}`);
        } finally {
            setIsDeletingOpeningOrClosingVideo(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Initialize voice recorder
    const initializeRecorder = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);

            const recorder = new MediaRecorder(stream, {
                mimeType: 'audio/webm'
            });
    
            const chunks = [];
            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                }
            };

            recorder.onstop = async () => {
                const audioBlob = new Blob(chunks, { type: 'audio/webm' });
                const finalDuration = recordingDuration; // Capture duration immediately
                console.log('Final recording duration:', recordingDuration); // Should show actual duration
                console.log('Final finalDuration:', finalDuration); // Should show actual duration

                try {
                    // Create FormData and append both audio and duration
                    const formData = new FormData();
                    formData.append('audio', audioBlob, 'recording.webm');
                    formData.append('duration', finalDuration); // Use captured duration
                    console.log('FormData duration:', formData.get('duration'));

                    const response = await api.saveRecording(formData);
                    console.log('Recording saved:', response.data);
                    if (response.data.success) {
                        setRecordings(prev => [...prev, response.data.recording]);
                        // Store the duration in local state
                        setRecordingLength(prev => ({
                            ...prev,
                            [response.data.recording._id]: finalDuration
                        }));
                    }
                } catch (error) {
                    console.error('Error saving recording:', error);
                } finally {
                    setIsSavingRecording(false);
                    setIsRecording(false);
                }
                chunks.length = 0; // Clear the chunks array
            };

            setMediaRecorder(recorder);
            return recorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            return null;
        }
    };

    // Handle recording controls
    const startRecording = async () => {
        trackEvent('recording_started', {
            userId,
        });
        if (!recordings || recordings.length === 0) {
            setShowRecordingTips(true);
            return;
        }
        else if (!mediaRecorder) {
            // Initialize recorder when user clicks start
            const recorder = await initializeRecorder();
            if (recorder) {
                // Clear any existing interval first
                if (timerInterval) {
                    clearInterval(timerInterval);
                }

                recorder.start();
                // Start timer
                setRecordingDuration(0);
                console.log('Starting timer at:', 0);

                const interval = setInterval(() => {
                    setRecordingDuration(prev => {
                        console.log('Timer tick:', prev + 1, 'at:', Date.now());
                        return prev + 1;
                    });
                }, 1000);

                setTimerInterval(interval);
                setIsRecording(true);
            }
        } else if (mediaRecorder.state === 'inactive') {
            // Clear any existing interval first
            if (timerInterval) {
                clearInterval(timerInterval);
            }

            mediaRecorder.start();
            setIsRecording(true);
            // Start timer
            setRecordingDuration(0);

            const interval = setInterval(() => {
                setRecordingDuration(prev => {
                    console.log('Timer tick:', prev + 1, 'at:', Date.now());
                    return prev + 1;
                });
            }, 1000);
            setTimerInterval(interval);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            const finalDuration = recordingDuration;
            console.log('Stopping recording at:', Date.now(), 'duration:', finalDuration);

            setIsSavingRecording(true);
            mediaRecorder.stop();

            // Stop timer
            if (timerInterval) {
                clearInterval(timerInterval);
                setTimerInterval(null);
            }

            // Store the duration with the recording
            const tempKey = Date.now();
            setRecordingLength(prev => ({
                ...prev,
                [tempKey]: formatTime(finalDuration)
            }));

            // Stop all tracks in the media stream
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
                setMediaStream(null);
            }

            // Clear the media recorder
            setMediaRecorder(null);
            trackEvent('recording_completed', {
                userId,
                duration: finalDuration
            });
        }
    };

    // Handle voice cloning
    const handleVoiceCloning = async () => {
        try {
            setIsGeneratingVoice(true);
            
            console.log('Recordings to send:', recordings);
            
            if (!recordings || recordings.length === 0) {
                throw new Error('No recordings found. Please record some samples first.');
            }
    
            // Create FormData
            const formData = new FormData();
            
            // Append each recording URL as a separate field
            recordings.forEach((recording, index) => {
                formData.append(`recording${index}`, recording.url);
            });
    
            // Add the total number of recordings
            formData.append('recordingCount', recordings.length);
    
            // For debugging - log the FormData contents
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }
    
            // Clone the voice using ElevenLabs API
            const cloneResponse = await api.cloneVoice(formData);
    
            console.log('Voice cloning response:', cloneResponse.data);
            
            if (cloneResponse.data && cloneResponse.data.voice_id) {
                setUserVoiceId(cloneResponse.data.voice_id);
                await api.updateProfile({ voiceId: cloneResponse.data.voice_id });
                alert('Voice cloned successfully!');
            }
            trackEvent('voice_cloning_completed', {
                userId,
                voiceId: cloneResponse.data.voice_id
            });
        } catch (error) {
            console.error('Error cloning voice:', error);
            alert(error.message || 'Failed to clone voice. Please try again.');
            trackEvent('voice_cloning_error', {
                userId,
                error: error.message
            });
        } finally {
            setIsGeneratingVoice(false);
        }
    };

    // Test cloned voice
    const testClonedVoice = async () => {
        if (!userVoiceId) {
            alert('Please clone your voice first');
            return;
        }
    
        try {
            setIsTestingVoice(true);
            const response = await api.testClonedVoice(
                "Hello! This is my cloned voice. How does it sound?",
                userVoiceId
            );
            
            // The response will now contain the S3 URL
            if (response.data && response.data.audioUrl) {
                setTestAudioUrl(response.data.audioUrl); // Update this line
            }
            trackEvent('voice_test_completed', {
                userId,
                voiceId: userVoiceId,
                audioUrl: response.data.audioUrl
            });
        } catch (error) {
            console.error('Error testing cloned voice:', error);
            alert('Failed to test voice. Please try again.');
            trackEvent('voice_test_error', {
                userId,
                error: error.message
            });
        } finally {
            setIsTestingVoice(false);
        }
    };

    // Handle LinkedIn URL submission
    // const handleAddProspect = async (e) => {
    //     e.preventDefault();
        
    //     // Basic URL validation
    //     if (!newProspectUrl.includes('linkedin.com/')) {
    //         setScrapingError('Please enter a valid LinkedIn URL');
    //         return;
    //     }

    //     // Check if URL already exists
    //     if (prospects.some(p => p.linkedinUrl === newProspectUrl)) {
    //         setScrapingError('This profile has already been added');
    //         return;
    //     }

    //     setScrapingError('');
    //     setIsScrapingProfile(true);
    //     setProcessingUrls(prev => new Set(prev).add(newProspectUrl));

    //     try {
    //         const response = await api.post('/api/scrape-linkedin-profile', {
    //             url: newProspectUrl
    //         });
    //         const newProspect = response.data;
            
    //         setProspects(prev => [...prev, {
    //             id: `prospect-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
    //             linkedinUrl: newProspectUrl,
    //             firstName: newProspect.firstName,
    //             lastName: newProspect.lastName,
    //             company: newProspect.company,
    //             jobTitle: newProspect.jobTitle,
    //             dateAdded: new Date().toISOString(),
    //             sent: false,
    //             customPitch: '',
    //             videoUrl: '',
    //             viewCount: 0
    //         }]);
            
    //         setNewProspectUrl('');
    //     } catch (error) {
    //         console.error('Error scraping profile:', error);
    //         setScrapingError(
    //             error.response?.data?.message || 
    //             'Failed to fetch LinkedIn profile. Please check the URL and try again.'
    //         );
    //     } finally {
    //         setIsScrapingProfile(false);
    //         setProcessingUrls(prev => {
    //             const newSet = new Set(prev);
    //             newSet.delete(newProspectUrl);
    //             return newSet;
    //         });
    //     }
    //};

    // Delete prospect
    const handleDeleteProspect = async (prospectId) => {
        if (!prospectId) {
            console.error('No prospect ID provided');
            return;
        }

        if (window.confirm('Are you sure you want to delete this prospect?')) {
            setIsDeleting(prev => new Set(prev).add(prospectId));
            
            try {
                await api.deleteProspect(prospectId);
                setProspects(prospects.filter(p => p._id !== prospectId)); // Immediate UI update
                await fetchUserProspects();
                trackEvent('prospect_deleted', {
                    userId,
                    prospectId
                });
                // Show success message (if you have a notification system)
                // showNotification('Prospect deleted successfully');

            } catch (error) {
                console.error('Error deleting prospect:', error);
                trackEvent('prospect_delete_error', {
                    userId,
                    prospectId,
                    error: error.message
                });
                // Show error message
                // showNotification('Failed to delete prospect', 'error');
            } finally {
                setIsDeleting(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }
        }
    };

    const SubscriptionModal = () => {
        const [promoCode, setPromoCode] = useState('');
        const [isValidatingCode, setIsValidatingCode] = useState(false);
        const [codeError, setCodeError] = useState('');
    
        const handleSubscribe = async (promoCodeToUse = '') => {
            setIsSubscribing(true);
            setSubscriptionError('');
            
            try {
                // Create checkout session
                const response = await api.post('/api/create-checkout-session', {
                    promoCode: promoCodeToUse
                });
                
                // Redirect to Stripe Checkout
                window.location.href = response.data.url;
            } catch (error) {
                setSubscriptionError(error.response?.data?.message || 'Failed to start subscription process');
                setIsSubscribing(false);
            }
        };
    
        const validatePromoCode = async () => {
            setIsValidatingCode(true);
            setCodeError('');
            
            try {
                const response = await api.post('/api/validate-promo-code', {
                    code: promoCode
                });
                
                if (response.data.valid) {
                    if (response.data.type === 'beta') {
                        // If it's a beta code, update the user's profile
                        await api.updateProfile({ isBetaUser: true });
                        // Update local state
                        setUserProfile(prev => ({ ...prev, isBetaUser: true }));
                        // Close the modal
                        setShowSubscriptionModal(false);
                        // Optionally show a success message
                        alert('Beta access granted! You now have unlimited video generation.');
                    } else {
                        // For regular promo codes, proceed with subscription
                        handleSubscribe(promoCode);
                    }
                } else {
                    setCodeError('Invalid promo code');
                }
            } catch (error) {
                setCodeError(error.response?.data?.message || 'Failed to validate code');
            } finally {
                setIsValidatingCode(false);
            }
        };
    
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    backgroundColor: '#27292D',
                    padding: '30px',
                    borderRadius: '10px',
                    maxWidth: '500px',
                    width: '90%',
                    border: '1px solid #222529',
                    color: 'white'
                }}>
                    <h3 style={{ marginBottom: '20px' }}>Subscribe to Generate More Videos</h3>
                    
                    <div style={{ marginBottom: '20px', color: '#ffffff95' }}>
                        <p>Unlock unlimited video generation for $30/month</p>
                        <ul style={{ marginTop: '15px', paddingLeft: '20px' }}>
                            <li>Generate unlimited personalized videos</li>
                            <li>Second month free for new users</li>
                            <li>Cancel anytime</li>
                        </ul>
                    </div>
    
                    {/*<div style={{ marginBottom: '20px' }}>
                        <input
                            type="text"
                            placeholder="Have a promo code?"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                marginBottom: '8px',
                                borderRadius: '4px',
                                border: '1px solid #dfe1e5',
                                backgroundColor: '#2A2B2F',
                                color: 'white'
                            }}
                        />
                        {codeError && <p style={{ color: '#ff4444', fontSize: '14px' }}>{codeError}</p>}
                    </div>*/}
    
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <button
                            onClick={() => setShowSubscriptionModal(false)}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                backgroundColor: 'transparent',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            Cancel
                        </button>
                        {/* {promoCode ? (
                            <button
                                onClick={validatePromoCode}
                                className="main-button"
                                disabled={isValidatingCode || isSubscribing}
                            >
                                {isValidatingCode ? 'Validating...' : 'Apply & Subscribe'}
                            </button>
                        ) : ( */}
                            <button
                                onClick={() => handleSubscribe()}
                                className="main-button"
                                disabled={isSubscribing}
                            >
                                {isSubscribing ? 'Redirecting...' : 'Subscribe Now'}
                            </button>
                        {/* )} */}
                    </div>
                    
                    {subscriptionError && (
                        <p style={{ color: '#ff4444', marginTop: '15px', textAlign: 'center' }}>
                            {subscriptionError}
                        </p>
                    )}
                </div>
            </div>
        );
    };
    

    // Generate pitch
    const handleGeneratePitch = async (prospectId, language = 'en') => {

        const startTime = Date.now();
        trackEvent('pitch_generation_started', {
            userId,
            prospectId,
            language
        });
        try {
            // Add only this specific prospect ID to the generating set
            setGeneratingPitch(prev => new Set([...prev, prospectId]));

            // Check if we're doing a text-only video
            // const isTextOnly = !openingVideoUrl || !closingVideoUrl;
            const isTextOnly = true;
    
            const response = await api.generatePitch(prospectId, isTextOnly, language);
            
            // Update the prospect's pitch in your prospects state
            setProspects(prevProspects => 
                prevProspects.map(p => 
                    p._id === prospectId 
                        ? { ...p, customPitch: response.data.pitch }
                        : p
                )
            );
            trackEvent('pitch_generation_completed', {
                userId,
                prospectId,
                language,
                processingTime: Date.now() - startTime,
                pitchLength: response.data.pitch.length
            });
        } catch (error) {
            console.error('Error generating pitch:', error);
            trackEvent('pitch_generation_error', {
                userId,
                prospectId,
                language,
                error: error.message
            });
            // Handle error appropriately
        } finally {
            // Remove only this specific prospect ID from the generating set
            setGeneratingPitch(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        }
    };

    // Debounce pitch saving
    const debouncedSavePitch = useCallback(
        async (prospectId, pitch) => {
            try {
                setSavingPitch(prev => new Set(prev).add(prospectId));
                await api.updateProspectPitch(prospectId, pitch);
            } catch (error) {
                console.error('Error saving pitch:', error);
            } finally {
                setSavingPitch(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }
        },
        [setSavingPitch] // Add the state setter as a dependency
    );

    // Handle pitch change
    const handlePitchChange = (prospectId, newPitch) => {
        // Update local state immediately for responsive UI
        setProspects(prevProspects =>
            prevProspects.map(p =>
                p._id === prospectId
                    ? { ...p, customPitch: newPitch }
                    : p
            )
        );
        
        // Show saving indicator
        setSavingPitch(prev => new Set([...prev, prospectId]));
        
        // Use your existing debounced save function
        debouncedSavePitch(prospectId, newPitch);
    };

    // Speech generation & video loading
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.getUserProfile();
                console.log('Full user data:', response.data);
                const userData = response.data;
                
                if (userData.voiceId) {
                    console.log('Found voice ID:', userData.voiceId);
                    setUserVoiceId(userData.voiceId);
                    setShowRecordingSection(false);
                } else {
                    console.log('No voice ID found in user data');
                    setShowRecordingSection(true);
                }

                // Set video URLs if they exist
                if (userData.openingVideoUrl) {
                    setOpeningVideoUrl(userData.openingVideoUrl);
                }
                if (userData.closingVideoUrl) {
                    setClosingVideoUrl(userData.closingVideoUrl);
                }

                if (response.data.recordings) {
                    setRecordings(response.data.recordings);
                }

                console.log('User voice ID:', userData.voiceId);

                // Fetch prospects
                // const prospectsResponse = await api.getLinkedInProspects();
                // setProspects(prospectsResponse.data);

                trackEvent('user_profile_fetched', {
                    userId,
                    voiceId: userData.voiceId,
                    openingVideoUrl: userData.openingVideoUrl,
                    closingVideoUrl: userData.closingVideoUrl
                });

            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
    
        fetchUserData();
    }, []);

    // When deleting a recording
    const handleDeleteRecording = async (recordingId) => {
        try {
            console.log('Deleting recording:', recordingId);
            await api.deleteRecording(recordingId);
            // Update the recordings list by filtering out the deleted recording
            setRecordings(prev => prev.filter(rec => rec._id !== recordingId));
            // Clear the recording length for this recording
            setRecordingLength(prev => {
                const newLengths = { ...prev };
                delete newLengths[recordingId];
                return newLengths;
            });
            trackEvent('recording_deleted', {
                userId,
                recordingId
            });
        } catch (error) {
            console.error('Error deleting recording:', error);
            trackEvent('recording_delete_error', {
                userId,
                recordingId,
                error: error.message
            });
        }
    };

    // Clean up resources when component unmounts
    useEffect(() => {
        // Store ref value in effect scope
        // const currentWavesurferInstances = wavesurferInstances.current;
        const currentRecordings = recordings; // Capture current recordings

        return () => {
            // Only revoke URLs when component unmounts
            currentRecordings.forEach(recording => {
                if (recording.url) {
                    URL.revokeObjectURL(recording.url);
                }
            });

            // Clean up wavesurfer instances
            // Object.values(currentWavesurferInstances).forEach(instance => {
            //     if (instance) {
            //         instance.destroy();
            //     }
            // });

            // Stop media recorder if active
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
            }

            // Clean up media stream
            if (mediaRecorder) {
                mediaRecorder.stream.getTracks().forEach(track => track.stop());
            }

            // Clean up timer
            if (timerInterval) {
                clearInterval(timerInterval);
            }

            // Clear recording lengths
            setRecordingLength({});
        };
    }, [mediaRecorder, timerInterval, recordings]);

    const handleGenerateSpeech = async (prospectId, pitch) => {
        const startTime = Date.now();
        trackEvent('speech_generation_started', {
            userId,
            prospectId
        });
        if (!pitch) {
            alert('Please enter a pitch before generating speech');
            return;
        }

        if (!userVoiceId) {
            alert('Please clone your voice first before generating speech');
            return;
        }

        setGeneratingSpeech(prev => new Set(prev).add(prospectId));
        
        try {
            const formattedPitch = pitch.trim().endsWith('.') ? pitch : `${pitch.trim()}.`;
            const response = await api.generateSpeech(formattedPitch, userVoiceId, prospectId, true);
            console.log('Speech generation response:', response);

            // Check if response.data is already a JSON object
            const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            
            if (data.audioUrl) {
                setAudioUrls(prev => ({
                    ...prev,
                    [prospectId]: data.audioUrl + `?t=${Date.now()}`
                }));

                setProspects(prev => prev.map(p => {
                    if (p._id === prospectId) {
                        return {
                            ...p,
                            generatedSpeech: data.audioUrl + `?t=${Date.now()}`
                        };
                    }
                    return p;
                }));
            }

            trackEvent('speech_generation_completed', {
                userId,
                prospectId,
                processingTime: Date.now() - startTime,
                audioLength: response.data.audioUrl.length
            });
        
        } catch (error) {
            console.error('Error generating speech:', error);
            alert('Failed to generate speech. Please try again.');
            trackEvent('speech_generation_error', {
                userId,
                prospectId,
                error: error.message
            });
        } finally {
            setGeneratingSpeech(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        }
    };

    // Add this function to handle polling
    const startPollingVideoStatus = (prospectId, onComplete) => {
        const pollInterval = setInterval(async () => {
            try {
                const status = await api.checkVideoStatus(prospectId);
                
                if (status.data.status === 'completed') {
                    clearInterval(pollInterval);
                    onComplete({
                        videoUrl: status.data.videoUrl,
                        pageUrl: status.data.pageUrl,
                        status: 'completed'
                    });
                } else if (status.data.status === 'error') {
                    clearInterval(pollInterval);
                    alert(`Video generation failed: ${status.data.error}`);
                }
            } catch (error) {
                console.error('Error checking video status:', error);
                clearInterval(pollInterval);
            }
        }, 5000); // Poll every 5 seconds
    };

    // Video generation
    const handleGenerateVideo = async (prospectId) => {
        // Check subscription status, but allow beta users to bypass
        const isBetaEnabled = isBetaUser || userProfile.isBetaUser;

        // Check subscription status, but allow beta users to bypass
        if (userProfile.hasCreatedFirstVideo && !userProfile.isSubscribed && !isBetaEnabled) {
            setShowSubscriptionModal(true);
            return; // Exit early - don't generate video
        }

        const startTime = Date.now();
        trackEvent('video_generation_started', {
            userId,
            prospectId
        });
        const prospect = prospects.find(p => p._id === prospectId);
        
        // Validate required elements
        if (!prospect) {
            console.error('Prospect not found:', { 
                searchId: prospectId, 
                availableProspects: prospects.map(p => ({id: p._id, name: `${p.firstName} ${p.lastName}`}))
            });
            return;
        }
        
        // Check if we're doing a text-only video
        // const isTextOnly = !openingVideoUrl || !closingVideoUrl;
        const isTextOnly = true;

        if (!audioUrls[prospectId]) {
            alert('Please generate speech first');
            return;
        }

        // Show video sending tip immediately if it's the first video
        if (!userProfile.hasCreatedFirstVideo) {
            setShowVideoSendingTip(true);
        }

        setGeneratingVideo(prev => new Set(prev).add(prospectId));

        let newVideoData = null;
        
        try {
            console.log('Sending video generation request:', {
                prospectId,
                openingVideo: openingVideoUrl,
                closingVideo: closingVideoUrl,
                audioUrl: audioUrls[prospectId],
                isTextOnly,
                prospect: {
                    firstName: prospect.firstName,
                    lastName: prospect.lastName,
                    company: prospect.company,
                    jobTitle: prospect.jobTitle
                }
            });

            const response = await api.generateVideo({
                prospectId,
                openingVideo: openingVideoUrl,
                closingVideo: closingVideoUrl,
                audioUrl: audioUrls[prospectId],
                isTextOnly,
                prospect: {
                    firstName: prospect.firstName,
                    lastName: prospect.lastName,
                    company: prospect.company,
                    jobTitle: prospect.jobTitle
                }
            });

            if (!response.data.videoUrl) {
                throw new Error('No video URL returned');
            }

            console.log('Video generation response:', response.data);
            newVideoData = {
                pageUrl: response.data.pageUrl,
                videoUrl: response.data.videoUrl + `?timestamp=${Date.now()}`, // Add timestamp to force reload
                viewCount: 0
            };
            console.log('New video data:', newVideoData);

            // Update hasCreatedFirstVideo if this is the first video
            if (!userProfile.hasCreatedFirstVideo) {
                try {
                    await api.updateProfile({ hasCreatedFirstVideo: true });
                    setUserProfile(prev => ({ ...prev, hasCreatedFirstVideo: true }));
                } catch (updateError) {
                    console.error('Error updating hasCreatedFirstVideo:', updateError);
                }
            }

            const endTime = Date.now();
            trackEvent('video_generation_completed', {
                userId,
                prospectId,
                processingTime: endTime - startTime,
                videoType: isTextOnly ? 'text_only' : 'full'
            });

        } catch (error) {
            console.error('Error generating video:', error);
            alert('Failed to generate video. Please try again.');
            trackEvent('video_generation_error', {
                userId,
                prospectId,
                error: error.message
            });
        } finally {
            setGeneratingVideo(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        
            // Only update prospects if we received the video data
            if (newVideoData) {
                setProspects(prev => {
                    const updatedProspects = prev.map(p => 
                        p._id === prospectId  // Make sure we're using _id not id
                            ? { 
                                ...p, 
                                videoData: {
                                    ...p.videoData,
                                    ...newVideoData
                                }
                            }
                            : p
                    );
                    // Force a re-render by creating a new array
                    return [...updatedProspects];
                });
            }
        }
    };

    // Copy video link
    const handleCopyLink = async (prospectId) => {
        const prospect = prospects.find(p => p.id === prospectId);
        if (!prospect?.videoUrl) return;

        try {
            await navigator.clipboard.writeText(prospect.videoUrl);
            setCopiedLinks(prev => new Set(prev).add(prospectId));
            
            // Reset "Copied!" state after 2 seconds
            setTimeout(() => {
                setCopiedLinks(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }, 2000);
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    // Clean up audio URLs when component unmounts
    useEffect(() => {
        return () => {
            Object.values(audioUrls).forEach(url => {
                URL.revokeObjectURL(url);
            });
        };
    }, [audioUrls]);

    // Function to create waveform
    // const createWaveform = (recording, index) => {
    //     if (wavesurferInstances.current[index]) {
    //         wavesurferInstances.current[index].destroy();
    //     }

    //     const wavesurfer = WaveSurfer.create({
    //         container: waveformRefs.current[index],
    //         waveColor: '#AB5898',
    //         progressColor: '#5C4BD3',
    //         cursorColor: '#000000',
    //         barWidth: 2,
    //         barGap: 1,
    //         height: 40,
    //         barRadius: 3,
    //         normalize: true,
    //         responsive: true,
    //         interact: true,
    //     });

    //     wavesurfer.loadBlob(recording.blob);
    //     wavesurferInstances.current[index] = wavesurfer;

    //     // Add loading error handler
    //     wavesurfer.on('error', error => {
    //         console.error('WaveSurfer error:', error);
    //     });

    //     return wavesurfer;
    // };

    // Clean up wavesurfer instances on unmount
    // useEffect(() => {
    //     // Store the current instances in a variable inside the effect
    //     const currentInstances = wavesurferInstances.current;
    
    //     // Cleanup function
    //     return () => {
    //         Object.values(currentInstances).forEach(instance => {
    //             if (instance) {
    //                 instance.destroy();
    //             }
    //         });
    //     };
    // }, []); // Empty dependency array since we only want this to run on mount/unmount

    // Initialize random examples when showing them
    useEffect(() => {
        if (showExamples) {
            // Shuffle array and take first 10 examples
            const shuffled = [...RECORDING_EXAMPLES]
                .sort(() => Math.random() - 0.5)
                .slice(0, 10);
            setDisplayedExamples(shuffled);
            setCurrentExample(0);
        }
    }, [showExamples]);

    // Rotate through selected examples
    useEffect(() => {
        let interval;
        if (showExamples && displayedExamples.length > 0) {
            interval = setInterval(() => {
                setCurrentExample((prev) => (prev + 1) % displayedExamples.length);
            }, 5000); // Change example every 5 seconds
        }
        return () => clearInterval(interval);
    }, [showExamples, displayedExamples]);

    // Fetch user's videos on mount
    useEffect(() => {
        const fetchUserVideos = async () => {
            try {
                const response = await api.get('/api/user/videos');
                const { openingVideoUrl, closingVideoUrl } = response.data;
                setOpeningVideoUrl(openingVideoUrl || '');
                setClosingVideoUrl(closingVideoUrl || '');
            } catch (error) {
                console.error('Error fetching user videos:', error);
            }
        };

        fetchUserVideos();
    }, []);

    /*const onDropLinkedinScreenshot = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            setLinkedinScreenshot(acceptedFiles[0]);
        }
    }, []);
    
    const {
        getRootProps: getLinkedinScreenshotRootProps,
        getInputProps: getLinkedinScreenshotInputProps,
        isDragActive: isLinkedinScreenshotDragActive
    } = useDropzone({
        onDrop: onDropLinkedinScreenshot,
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg']
        },
        maxFiles: 1
    });*/

    // Add this function to handle both URL and screenshot submission
    // OLD way, with the scraping from Naveed
    /*const handleProspectSubmission = async () => {
        if (!linkedinUrl) {
            setError('Please provide a LinkedIn profile URL');
            return;
        }
    
        setError('');
        
        setIsProcessingLinkedinScreenshot(true);

        try {
            const { data: newProspect } = await api.post('/api/prospects/scrape-linkedin-profile', {
                url: linkedinUrl
            });
    
            // Reset form
            setLinkedinUrl('');
            
            // Refresh the prospects list
            await fetchUserProspects();
    
            // Optional: Show success message with prospect name
            console.log(`Successfully added ${newProspect.firstName} ${newProspect.lastName} to prospects!`);
        } catch (error) {
            console.error('Error processing prospect:', error);
            setError(
                error.response?.data?.message || 
                'Failed to process profile. Please try again.'
            );
        } finally {
            setIsProcessingLinkedinScreenshot(false);
        }
    };*/

    // NEW way, with the scraping from Scrapin.io
    const handleProspectSubmission = async () => {
        if (!linkedinUrl) {
            setError('Please provide a LinkedIn profile URL');
            return;
        }
    
        setError('');
        setIsProcessingLinkedinScreenshot(true);

        console.log('User info being sent:', {
            name: userInfo.name,
            picture: userInfo.picture
        });
    
        try {
            // Use the new API endpoint that handles Scrapin.io
            const { data: newProspect } = await api.scrapeLinkedInProfile(linkedinUrl, userInfo.name, userInfo.picture);
            
            // Refresh the prospects list
            await fetchUserProspects();
    
            // Optional: Show success message
            console.log(`Successfully added ${newProspect.firstName} ${newProspect.lastName} to prospects!`);
            trackEvent('prospect_added', {
                userId,
                prospectId: newProspect._id,
                prospectName: `${newProspect.firstName} ${newProspect.lastName}`
            });
        } catch (error) {
            console.error('Error processing prospect:', error);
            setError(
                error.response?.data?.message || 
                'Failed to process profile. Please try again.'
            );
            trackEvent('prospect_add_error', {
                userId,
                error: error.message
            });
        } finally {
            setIsProcessingLinkedinScreenshot(false);
            // Reset form
            setLinkedinUrl('');
        }
    };

    // In your component, add this function to handle status changes
    const handleStatusChange = async (prospectId, newStatus) => {
        // Immediately update the UI
        setProspects(prev =>
            prev.map(p =>
                p._id === prospectId
                    ? { ...p, status: newStatus }
                    : p
            )
        );

        trackEvent('prospect_status_changed', {
            userId,
            prospectId,
            newStatus
        });
    
        try {
            // Then update the backend
            await api.patch(`api/prospects/${prospectId}/status`, { status: newStatus });
        } catch (error) {
            console.error('Error updating status:', error);
            // Revert the change if the server update fails
            setProspects(prev =>
                prev.map(p =>
                    p._id === prospectId
                        ? { ...p, status: p.status }
                        : p
                )
            );
            // Optionally show an error message to the user
            alert('Failed to update status. Please try again.');
        }
    };

    const handleDownloadVideo = async (videoUrl, prospectName) => {
        trackEvent('video_download_started', {
            userId,
            prospectName,
            videoUrl
        });
        try {
            const response = await fetch(videoUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(
                new Blob([blob], { type: 'video/mp4' })  // Add explicit MIME type
            );
            // Remove diacritical marks from the prospect name
            const normalizedName = prospectName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            const a = document.createElement('a');
            a.href = url;
            a.download = `video_${normalizedName}.mp4`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            trackEvent('video_download_completed', {
                userId,
                prospectName,
                videoUrl
            });
        } catch (error) {
            console.error('Error downloading video:', error);
            alert('Failed to download video');
            trackEvent('video_download_error', {
                userId,
                prospectName,
                videoUrl,
                error: error.message
            });
        }
    };

    const validateLinkedinUrl = (url) => {
        if (!url) {
            return "Please enter a LinkedIn URL";
        }
    
        // Nettoyer et normaliser l'URL
        let normalizedUrl = url.trim();
        
        // Ajouter https:// si nécessaire
        if (!normalizedUrl.startsWith('http://') && !normalizedUrl.startsWith('https://')) {
            normalizedUrl = 'https://' + normalizedUrl;
        }
        
        // Ajouter www. si nécessaire
        if (!normalizedUrl.includes('www.')) {
            normalizedUrl = normalizedUrl.replace('https://', 'https://www.');
        }
    
        // Regex pour vérifier le format linkedin.com/in/
        const linkedinProfileRegex = /^https?:\/\/(?:www\.)?linkedin\.com\/in\//i;
        
        try {
            const url = new URL(normalizedUrl);
            if (!linkedinProfileRegex.test(normalizedUrl)) {
                return "Please enter a valid LinkedIn profile URL (e.g., linkedin.com/in/username). Company profiles are not supported.";
            }
            // URL est valide, mettre à jour l'URL avec la version normalisée
            setLinkedinUrl(normalizedUrl);
            return null;
        } catch (e) {
            return "Please enter a valid URL";
        }
    };

    useEffect(() => {
        let timer;
        if (showBetaMessage) {
            timer = setTimeout(() => {
                setShowBetaMessage(false);
            }, 5000); // 5000ms = 5 secondes
        }
        
        // Cleanup function pour éviter les memory leaks
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [showBetaMessage]);

    // Add this CSS to your existing styles
    /*const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '20px',
        backgroundColor: isLinkedinScreenshotDragActive ? '#f0f0f0' : '#ffffff'
    };*/

    const calculateYearProgress = () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), 0, 0); // Début de l'année
        const diff = now - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const dayOfYear = Math.floor(diff / oneDay);
        const progress = (dayOfYear / 365) * 100;
        return progress.toFixed(1); // Retourne le pourcentage avec 1 décimale
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showRecordingSection, setShowRecordingSection] = useState(false);

    const ShortcutHelp = () => (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#27292D',
            padding: '15px',
            borderRadius: '8px',
            border: '1px solid #222529',
            color: 'white',
            fontSize: '12px'
        }}>
            <h4 style={{ marginTop: 0, marginBottom: '10px' }}>Keyboard Shortcuts</h4>
            <ul style={{ 
                listStyle: 'none',
                padding: 0,
                margin: 0,
                color: '#ffffff95'
            }}>
                <li>⌘/Ctrl + L: Focus LinkedIn URL input</li>
                <li>⌘/Ctrl + S: Mark latest as sent</li>
                <li>⌘/Ctrl + 6: Generate Pitch</li>
                <li>⌘/Ctrl + 7: Generate Speech</li>
                <li>⌘/Ctrl + 8: Generate Video</li>
                <li>⌘/Ctrl + 9: Download Video</li>
            </ul>
        </div>
    );

    useEffect(() => {
        const handleKeyboardShortcuts = (e) => {
            // Check if Cmd (Mac) or Ctrl (Windows) is pressed
            const isCmdOrCtrl = e.metaKey || e.ctrlKey;
            
            if (!isCmdOrCtrl) return;
    
            switch (e.key.toLowerCase()) { // Add toLowerCase() to handle both upper and lower case
                case 'l':
                    e.preventDefault();
                    linkedinUrlInputRef.current?.focus();
                    break;
                case '6': {
                    e.preventDefault();
                    const latestProspect = prospects[0];
                    if (latestProspect) {
                        handleGeneratePitch(latestProspect._id);
                    }
                    break;
                }
                case '7': {
                    e.preventDefault();
                    const latestProspect = prospects[0];
                    if (latestProspect?.customPitch) {
                        handleGenerateSpeech(latestProspect._id, latestProspect.customPitch);
                    } else {
                        alert('Please generate a pitch first');
                    }
                    break;
                }
                case '8': {
                    e.preventDefault();
                    const latestProspect = prospects[0];
                    if (latestProspect) {
                        handleGenerateVideo(latestProspect._id);
                    }
                    break;
                }
                case '9': {
                    e.preventDefault();
                    const latestProspect = prospects[0];
                    if (latestProspect?.videoData?.videoUrl) {
                        handleDownloadVideo(
                            latestProspect.videoData.videoUrl, 
                            `${latestProspect.firstName} ${latestProspect.lastName}`
                        );
                    } else {
                        alert('Please generate a video first');
                    }
                    break;
                }
                case 's': {
                    e.preventDefault();
                    const latestProspect = prospects[0];
                    if (latestProspect) {
                        handleStatusChange(latestProspect._id, 'video_sent');
                    }
                    break;
                }
                default:
                    break;
            }
        };
    
        document.addEventListener('keydown', handleKeyboardShortcuts);
        return () => document.removeEventListener('keydown', handleKeyboardShortcuts);
    }, [prospects]); // Add other dependencies if needed    

    return (
        <div
            style={{ 
                minHeight: '100vh', 
                padding: '20px',
                backgroundColor: "#181818",//'#FBFBFB', //Dark mode
                boxSizing: 'border-box'
            }}
        >
            {/* Header */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
                <h1 style={{color:'white'}} /*Dark mode*/ >Merra.ai</h1>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {userProfile.isSubscribed && (
                        <span style={{
                            backgroundColor: '#000000',
                            border: '1px solid #FFD700',
                            color: '#FFD700',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            letterSpacing: '0.5px'
                        }}>
                            PRO
                        </span>
                    )}
                    <button 
                        className="profile-button"
                        onClick={() => setShowProfileModal(true)}
                        style={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: 0,
                            backgroundColor: userInfo.picture ? 'transparent' : '#27292D',
                        }}
                    >
                        {userInfo.picture ? (
                            <img 
                                src={userInfo.picture} 
                                alt="Profile" 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                }}
                            />
                        ) : (
                            <i className="fas fa-user" style={{ color: 'white' }}></i>
                        )}

                        {/* Notification Dot */}
                        {!profileComplete && (
                            <span style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                width: '12px',
                                height: '12px',
                                backgroundColor: '#ff4444',
                                borderRadius: '50%',
                                border: '2px solid white',
                            }} />
                        )}
                    </button>
                </div>
            </div>

            {/* Profile Modal */}
            {showProfileModal && <ProfileModal />}

            {/* Recording Tips */}
            {showRecordingTips && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: '#27292D',
                        padding: '30px',
                        borderRadius: '10px',
                        maxWidth: '500px',
                        border: '1px solid #222529',
                        color: 'white'
                    }}>
                        <h3 style={{ marginBottom: '20px', color: '#ffffff' }}>Tips for Best Voice Cloning Results</h3>
                        
                        <ul style={{ 
                            listStyle: 'none', 
                            padding: 0,
                            marginBottom: '25px',
                            color: '#ffffff95'
                        }}>
                            <li style={{ marginBottom: '12px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Mic size={16} />
                                Speak naturally in the tone you want your AI voice to have
                            </li>
                            <li style={{ marginBottom: '12px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Volume2 size={16} />
                                Avoid background noise and echoes
                            </li>
                            <li style={{ marginBottom: '12px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Pause size={16} />
                                Try to speak fluently with minimal pauses
                            </li>
                            <li style={{ marginBottom: '12px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Music size={16} />
                                Avoid humming or clearing your throat
                            </li>
                        </ul>

                        <p style={{ 
                            marginBottom: '25px',
                            color: '#ffffff95',
                            borderTop: '1px solid #222529',
                            paddingTop: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <RefreshCw size={16} />
                            Don't worry! You can re-record as many times as you want
                        </p>

                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'flex-end'
                        }}>
                            <button
                                onClick={async () => {
                                    setShowRecordingTips(false);
                                    if (!mediaRecorder) {
                                        // Initialize recorder when user clicks start
                                        const recorder = await initializeRecorder();
                                        if (recorder) {
                                            // Clear any existing interval first
                                            if (timerInterval) {
                                                clearInterval(timerInterval);
                                            }
                            
                                            recorder.start();
                                            // Start timer
                                            setRecordingDuration(0);
                                            console.log('Starting timer at:', 0);
                            
                                            const interval = setInterval(() => {
                                                setRecordingDuration(prev => {
                                                    console.log('Timer tick:', prev + 1, 'at:', Date.now());
                                                    return prev + 1;
                                                });
                                            }, 1000);
                            
                                            setTimerInterval(interval);
                                            setIsRecording(true);
                                        }
                                    } else if (mediaRecorder.state === 'inactive') {
                                        // Clear any existing interval first
                                        if (timerInterval) {
                                            clearInterval(timerInterval);
                                        }
                            
                                        mediaRecorder.start();
                                        setIsRecording(true);
                                        // Start timer
                                        setRecordingDuration(0);
                            
                                        const interval = setInterval(() => {
                                            setRecordingDuration(prev => {
                                                console.log('Timer tick:', prev + 1, 'at:', Date.now());
                                                return prev + 1;
                                            });
                                        }, 1000);
                                        setTimerInterval(interval);
                                    }
                                }}
                                className="main-button"
                            >
                                Got it, start recording
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showVideoSendingTip && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: '#27292D',
                        padding: '30px',
                        borderRadius: '10px',
                        maxWidth: '500px',
                        border: '1px solid #222529',
                        color: 'white'
                    }}>
                        <h3 style={{ marginBottom: '20px', color: '#ffffff', display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Send size={20} />
                            While we generate your video
                        </h3>
                        
                        <p style={{ 
                            marginBottom: '25px',
                            color: '#ffffff95',
                            lineHeight: '1.5'
                        }}>
                            Few tips to send videos: from their website, LinkedIn doesn't allow sending videos directly. So, to send your personalized video:
                        </p>

                        <ol style={{
                            color: '#ffffff95',
                            paddingLeft: '20px',
                            marginBottom: '25px',
                            lineHeight: '1.5'
                        }}>
                            <li style={{ marginBottom: '10px' }}>Download the video once it's generated</li>
                            <li style={{ marginBottom: '10px' }}>Transfer the video to your mobile device</li>
                            <li style={{ marginBottom: '10px' }}>Open LinkedIn on your mobile app</li>
                            <li>Send the video through the mobile app's messaging</li>
                        </ol>

                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'flex-end'
                        }}>
                            <button
                                onClick={() => {
                                    setShowVideoSendingTip(false);
                                }}
                                className="main-button"
                            >
                                Got it
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* error banner */}
            {backendError && (
                    <div style={{
                        backgroundColor: '#ff44444d',
                        color: '#ff4444',
                        padding: '12px 20px',
                        borderRadius: '8px',
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        border: '1px solid #ff4444',
                        maxWidth: '500px',
                        alignSelf: 'center',
                        justifySelf: 'center',
                    }}>
                        <AlertCircle size={20} />
                        {backendError}
                    </div>
                )}

            {/* Greeting */}
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <h2 style={{color:'white'}} /*Dark mode*/ >Hello {userName.split(' ')[0]}!</h2>
                <h2 className="gradient-text-dark" style={{justifySelf:"center", fontSize:'20px'}}>
                    Feeling awesome today?
                </h2>
            </div>

            {/* Main Content */}
            <div style={{ display: 'flex', gap: '20px', flexDirection: windowWidth > 768 ? 'row' : 'column' }}>

                {/* Company/Role Description */}
                <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        //border: '1px solid #dfe1e5',
                        border: '1px solid #222529',
                        backgroundColor: '#27292D',//'white', //Dark mode
                        overflowY: 'auto',
                    }}
                >
                    <h3 style={{color:'white'}} /*Dark mode*/ >Your Prospecting Profile</h3>
                    {/* <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Current Role & Experience
                        </label>
                        <textarea
                            value={prospectingProfile.currentRole}
                            onChange={(e) => setProspectingProfile({
                                ...prospectingProfile,
                                currentRole: e.target.value
                            })}
                            placeholder="Describe your current role, company, and experience..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px'
                            }}
                        />
                    </div> */}

                    {/* <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Company Purpose & Goals
                        </label>
                        <textarea
                            value={prospectingProfile.companyPurpose}
                            onChange={(e) => setProspectingProfile({
                                ...prospectingProfile,
                                companyPurpose: e.target.value
                            })}
                            placeholder="What does your company do? What are your goals?"
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px'
                            }}
                        />
                    </div> */}

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '20px', color:'#ffffff95'}} /*Dark mode*/ >
                            Target Audience & Value Proposition:
                        </label>
                        <textarea
                            value={prospectingProfile.targetAudience}
                            onChange={(e) => { 
                                setProspectingProfile({
                                    ...prospectingProfile,
                                    targetAudience: e.target.value
                                });
                                e.target.style.height = 'auto'; // Reset height
                                e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Set new height with max of 300px
                            }}
                            placeholder="Who are your ideal customers? What value do you provide?"
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px',
                                maxHeight: '300px',
                                boxSizing: 'border-box',
                                resize: 'none',
                                overflowY: 'auto',
                                transition: 'height 0.2s ease',
                                fontFamily: 'inherit',
                            }}
                        />
                    </div>

                    <button
                        onClick={handleProspectingProfileSave}
                        className="main-button"
                        style={{ 
                            position: 'relative',
                            backgroundColor: prospectingProfileSaveStatus === 'saved' ? '#ADEBB3' : // Green for success
                                            prospectingProfileSaveStatus === 'error' ? '#f44336' : // Red for error
                                            '#e1f679' //'#1a73e8' // Default blue
                        }}
                        disabled={prospectingProfileSaveStatus === 'saving'}
                    >
                        {prospectingProfileSaveStatus === 'saving' ? (
                            <>
                                <RefreshCw className="spin" size={16} style={{ marginRight: '8px' }} />
                                Saving...
                            </>
                        ) : prospectingProfileSaveStatus === 'saved' ? (
                            <>
                                <Check size={16} style={{ marginRight: '8px' }} />
                                Saved!
                            </>
                        ) : prospectingProfileSaveStatus === 'error' ? (
                            <>
                                <AlertCircle size={16} style={{ marginRight: '8px' }} />
                                Error Saving
                            </>
                        ) : (
                            'Save Prospecting Profile'
                        )}
                    </button>
                </div>

                {/* Voice Cloning Interface */}
                <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        //border: '1px solid #dfe1e5',
                        border: '1px solid #222529',
                        backgroundColor: '#27292D',//'white', //Dark mode
                        overflowY: 'auto',
                    }}
                >
                    <h3 style={{color:'white'}} /*Dark mode*/ >Voice Cloning</h3>
                    <div style={{ marginBottom: '20px' }}>
                        <p style={{ alignSelf:'flex-start', marginTop:'0px', color:'#ffffff95'}} /*Dark mode*/ >
                            Record your voice to create a digital voice clone.
                        </p>
                        <button
                            onClick={() => setShowRecordingSection(!showRecordingSection)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                padding: '8px 12px',
                                backgroundColor: '#2A2B2F',
                                border: '2px solid #dfe1e5',
                                borderRadius: '4px',
                                color: '#ffffff95',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                width: '180px'
                            }}
                        >
                            {showRecordingSection ? (
                                <>
                                    <ChevronUp size={16} />
                                    Hide Voice Cloning
                                </>
                            ) : (
                                <>
                                    <ChevronDown size={16} />
                                    Show Voice Cloning
                                </>
                            )}
                        </button>
                        {showRecordingSection && (
                        <div>
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            marginBottom: '10px' 
                        }}>
                            
                            <p style={{ fontSize:'14px', marginBottom: '10px', marginTop:'0px', color:'#666' }}>
                                Record 3-5 samples for the best results. 
                                Each recording should be 10-30 seconds long.
                            </p>
                            <button
                                onClick={() => setShowExamples(!showExamples)}
                                style={{
                                    padding: '2px 12px',
                                    backgroundColor: 'transparent',
                                    color: '#1a73e8',
                                    border: '1px solid #1a73e8',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    // whiteSpace: 'nowrap',
                                    marginLeft: '16px',
                                }}
                            >
                                {showExamples ? 'Hide inspiration' : 'Click for inspiration'}
                            </button>
                        </div>

                        {/* Example Sentences */}
                        {showExamples ? (
                            <div style={{
                                padding: '12px',
                                backgroundColor: '#f8f9fa',
                                borderRadius: '4px',
                                marginBottom: '16px',
                                border: '1px solid #dfe1e5',
                                position: 'relative',
                                overflow: 'hidden',
                                height: '60px' // Fixed height to prevent layout shifts
                            }}>
                                {displayedExamples.map((example, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '12px',
                                            right: '12px',
                                            transform: `translateY(-50%) translateY(${(index - currentExample) * 60}px)`,
                                            opacity: index === currentExample ? 1 : 0,
                                            transition: 'all 0.5s ease-in-out',
                                            textAlign: 'center',
                                            color: '#666'
                                        }}
                                    >
                                        "{example}"
                                    </div>
                                ))}
                            </div>
                        ):(
                            <p style={{fontSize:'14px', marginTop:'0px', marginBottom:'30px', color:'#666'}}>Not sure what to say? Click for inspiration.</p>
                        )}

                        {/* Progress Bar */}
                        <div style={{ marginBottom: '20px' }}>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                marginBottom: '8px',
                                fontSize: '0.9em',
                                color: '#666'
                            }}>
                                <span>Recording Progress</span>
                                <span>{recordings.length}/5 samples</span>
                            </div>
                            <div style={{
                                width: '100%',
                                height: '8px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '4px',
                                overflow: 'hidden'
                            }}>
                                <div style={{
                                    width: `${Math.min((recordings.length / 5) * 100, 100)}%`,
                                    height: '100%',
                                    background: 'linear-gradient(to right, #AB5898 50%, #5C4BD3 80%)',
                                    transition: 'width 0.3s ease',
                                    borderRadius: '4px',
                                }} />
                            </div>
                            {recordings.length < 5 && (
                                <p style={{ 
                                    fontSize: '0.8em', 
                                    color: '#666', 
                                    marginTop: '8px' 
                                }}>
                                    {5 - recordings.length} more {5 - recordings.length === 1 ? 'recording' : 'recordings'} needed
                                </p>
                            )}
                        </div>
                        
                        {/* Recording controls */}
                        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                            <button
                                onClick={isRecording ? stopRecording : startRecording}
                                className={`main-button ${isRecording ? 'recording' : ''}`}
                                style={{ 
                                    flex: 1,
                                    backgroundColor: (recordings.length >= 5 ? '#d9df70' : (isRecording ? (isSavingRecording ? '#ccc' : '#dc3545') : '#e1f679')),
                                    cursor: recordings.length >= 5 ? 'not-allowed' : 'pointer'
                                }}
                                disabled={recordings.length >= 5 && !isRecording}
                            >
                                {isRecording ? (
                                    <>
                                        {isSavingRecording ? <Loader size={16} style={{ marginRight: '5px' }} /> : <Square size={16} style={{ marginRight: '5px' }} />}
                                        {isSavingRecording ? 'Saving...' : <>'Stop Recording' ({formatTime(recordingDuration)})</>}
                                    </>
                                ) : (
                                    <>
                                        {recordings.length >=5 ? <Check size={16} style={{ marginRight: '5px' }} /> : <Mic size={16} style={{ marginRight: '5px' }} />}
                                        {recordings.length >= 5 ? 'Max Recordings Reached' : 'Start Recording'}
                                    </>
                                )}
                            </button>
                        </div>

                        {/* Recording list */}
                        <div style={{ marginBottom: '20px' }}>
                            {console.log('current recordings',recordings)}
                            {recordings.map((recording, index) => (
                                <div 
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        padding: '8px',
                                        backgroundColor: '#f8f9fa',
                                        borderRadius: '4px',
                                        marginBottom: '12px',
                                        border: '1px solid #dfe1e5'
                                    }}
                                >
                                    <div style={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'space-between',
                                        marginBottom: '4px'
                                    }}>
                                        <span style={{ 
                                            color: '#666',
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}>
                                            Sample {index + 1} ({recordingLength[recording._id] ? 
                                                formatTime(recordingLength[recording._id]) : 
                                                (recording.duration ? formatTime(recording.duration) : '')
                                            })
                                        </span>
                                        <button
                                            onClick={() => handleDeleteRecording(recording._id)}
                                            className='red-delete-button'
                                            style={{
                                                padding: '4px 8px',
                                                backgroundColor: '#dc3545',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                                fontSize: '0.8em'
                                            }}
                                        >
                                            <Trash2 size={14} />
                                            Delete
                                        </button>
                                    </div>

                                    {/* Simple audio player as fallback */}
                                    <audio 
                                        controls
                                        style={{ width: '100%', height: '12px' }}
                                        controlsList="nodownload"
                                        onError={(e) => {
                                            console.error('Audio playback error:', e.target.error);
                                            console.log('Audio URL:', recording.url);
                                            // Try to fetch the audio file directly to check for CORS or other issues
                                            fetch(recording.url)
                                                .then(response => {
                                                    console.log('Audio file response:', response);
                                                    return response.blob();
                                                })
                                                .then(blob => console.log('Audio blob:', blob))
                                                .catch(error => console.error('Error fetching audio:', error));
                                        }}
                                        onLoadStart={() => console.log('Audio loading started')}
                                        onLoadedMetadata={(e) => {
                                            // Format duration when metadata is loaded
                                            console.log('Audio metadata loaded, duration:', e.target.duration);
                                            if (isFinite(e.target.duration)) {
                                                const totalSeconds = Math.floor(e.target.duration);
                                                const minutes = Math.floor(totalSeconds / 60);
                                                const seconds = totalSeconds % 60;
                                                setRecordingLength(prev => ({
                                                    ...prev,
                                                    [recording._id]: `${minutes}:${seconds.toString().padStart(2, '0')}`
                                                }));
                                            }
                                        }}
                                        onLoadedData={() => console.log('Audio data loaded')}
                                    >
                                        <source 
                                            src={recording.url} 
                                            type="audio/webm"
                                            onError={(e) => console.error('Source error:', e)}
                                        />
                                        Your browser does not support the audio element.
                                    </audio>
                
                                    {/* Waveform container */}
                                    {/* <div
                                        ref={el => {
                                            waveformRefs.current[index] = el;
                                            if (el && !wavesurferInstances.current[index]) {
                                                createWaveform(recording, index);
                                            }
                                        }}
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            border: '1px solid #eee'
                                        }}
                                    /> */}
                
                                    {/* Playback controls */}
                                    {/* <div style={{ 
                                        display: 'flex', 
                                        gap: '8px',
                                        justifyContent: 'center'
                                    }}>
                                        <button
                                            onClick={() => {
                                                const wavesurfer = wavesurferInstances.current[index];
                                                if (wavesurfer) {
                                                    if (wavesurfer.isPlaying()) {
                                                        wavesurfer.pause();
                                                    } else {
                                                        wavesurfer.play();
                                                    }
                                                }
                                            }}
                                            style={{
                                                padding: '4px 12px',
                                                backgroundColor: '#1a73e8',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                                fontSize: '0.9em'
                                            }}
                                        >
                                            {wavesurferInstances.current[index]?.isPlaying() ? (
                                                <>
                                                    <Pause size={14} />
                                                    Pause
                                                </>
                                            ) : (
                                                <>
                                                    <Play size={14} />
                                                    Play
                                                </>
                                            )}
                                        </button>
                                    </div> */}
                                </div>
                            ))}
                        </div>

                        {/* Clone voice button */}
                        {recordings.length >= 3 && (
                            <button
                                onClick={handleVoiceCloning}
                                className="main-button"
                                disabled={isGeneratingVoice || recordings.length < 3}
                                style={{ width: '100%' }}
                            >
                                {isGeneratingVoice ? (
                                    <>
                                        <RefreshCw className="spin" size={16} style={{marginRight:'5px'}}/>
                                        Generating Voice Clone...
                                    </>
                                ) : (
                                    'Create My AI Voice'
                                )}
                            </button>
                        )}

                    </div>)}


                        {/* Test cloned voice */}
                        {userVoiceId && (
                            <div>
                                <h4 style={{color:"white" /*Dark mode*/}}>Test Your AI Voice</h4>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <button
                                        onClick={testClonedVoice}
                                        className="main-button"
                                        disabled={isTestingVoice || !userVoiceId}
                                        style={{ flex: 1 }}
                                    >
                                        {isTestingVoice? (
                                            <>
                                                <RefreshCw className="spin" size={16} style={{marginRight:'5px'}}/>
                                                Generating...
                                            </>
                                        ) : (
                                            'Generate Test'
                                        )}
                                    </button>
                                    {testAudioUrl && (
                                        <button
                                            onClick={() => {
                                                if (audioRef.current) {
                                                    if (isPlayingVoice) {
                                                        audioRef.current.pause();
                                                    } else {
                                                        audioRef.current.play();
                                                    }
                                                    setIsPlayingVoice(!isPlayingVoice);
                                                }
                                            }}
                                            className="main-button"
                                            style={{ width: '50px' }}
                                        >
                                            {isPlayingVoice ? (
                                                <Pause size={16} />
                                            ) : (
                                                <Play size={16} />
                                            )}
                                        </button>
                                    )}
                                </div>
                                <audio
                                    ref={audioRef}
                                    src={testAudioUrl}
                                    onEnded={() => setIsPlayingVoice(false)}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Video Upload Section */}
                {ENABLE_VIDEO_UPLOAD && (<div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        //border: '1px solid #dfe1e5',
                        border: '1px solid #222529',
                        backgroundColor: '#27292D',//'white', //Dark mode
                        overflowY: 'auto',
                        display: window.innerWidth <= 768 ? 'none' : 'block', // Hide on small screens
                    }}
                >
                <h3 style={{color:'white'}} /*Dark mode*/ >Upload Videos <span style={{color:'#ffffff95', fontSize:'14px', fontWeight:'normal'}}>(optional)</span></h3>
                <p style={{color:'#ffffff95'}}>
                    Upload videos that will put before and after your AI-generated video.
                </p>
                    
                    {ENABLE_VIDEO_UPLOAD ? (<div>
                        {/* Opening Video Dropzone */}
                        <div style={{ marginBottom: '30px' }}>
                            <label style={{color:'white'}} /*Dark mode*/ >My Opening Video (optional):</label>
                            <div
                                {...getOpeningRootProps()}
                                style={{
                                    border: openingVideoUrl ? '2px solid #007bff' : '2px dashed #007bff',
                                    marginTop: '10px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: isOpeningDragActive ? '#f0f8ff' : 'white',
                                    position: 'relative',
                                    // minHeight: '100px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifySelf: 'center',
                                    maxWidth: '300px',
                                }}
                            >
                                <input {...getOpeningInputProps()} />
                                {openingVideoUrl ? (
                                    <div style={{ width: '100%' }}>
                                        <video
                                            style={{ 
                                                maxWidth: '100%', 
                                                // maxHeight: '150px',
                                                display:'block',
                                                borderRadius: '6px'
                                            }}
                                            controls
                                            crossOrigin="anonymous"
                                            key={openingVideoUrl}
                                        >
                                            <source src={openingVideoUrl} type={openingVideoUrl.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeVideo('opening');
                                            }}
                                            disabled={isDeletingOpeningOrClosingVideo}
                                            style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                                background: 'white',
                                                border: '1px solid #ddd',
                                                width: '28px',
                                                height: '28px',
                                                borderRadius: '50%',
                                                padding: '5px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                ) : (
                                    <div style={{padding:'20px'}}>
                                        <Video size={20} style={{ marginBottom: '10px' }} />
                                        <p style={{ margin: 0, fontSize:'14px' }}>Drop opening video here or click to select</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Closing Video Dropzone */}
                        <div>
                            <label style={{color:'white'}} /*Dark mode*/ >My Closing Video (optional):</label>
                            <div
                                {...getClosingRootProps()}
                                style={{
                                    border: closingVideoUrl ? '2px solid #007bff' : '2px dashed #007bff',
                                    marginTop: '10px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: isClosingDragActive ? '#f0f8ff' : 'white',
                                    position: 'relative',
                                    // minHeight: '100px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifySelf: 'center',
                                    maxWidth: '300px',
                                }}
                            >
                                <input {...getClosingInputProps()} />
                                {closingVideoUrl ? (
                                    <div style={{ width: '100%' }}>
                                        <video
                                            style={{
                                                maxWidth: '100%', 
                                                // maxHeight: '150px',
                                                display:'block',
                                                borderRadius: '6px'
                                            }}
                                            controls
                                            crossOrigin="anonymous"
                                            key={closingVideoUrl}
                                        >
                                            <source src={closingVideoUrl} type={closingVideoUrl.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeVideo('closing');
                                            }}
                                            disabled={isDeletingOpeningOrClosingVideo}
                                            style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                                background: 'white',
                                                border: '1px solid #ddd',
                                                width: '28px',
                                                height: '28px',
                                                borderRadius: '50%',
                                                padding: '5px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Trash2 size={16} />
                                        </button>
                                    </div>
                                ) : (
                                    <div style={{padding:'20px'}}>
                                        <Video size={20} style={{ marginBottom: '10px' }} />
                                        <p style={{ margin: 0, fontSize:'14px' }}>Drop closing video here or click to select</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    ) :
                    (
                        <div>
                            <div
                                style={{
                                    flex: 1,
                                    padding: '20px',
                                    borderRadius: '10px',
                                    border: '1px solid #222529',
                                    backgroundColor: '#27292D',
                                    overflowY: 'auto',
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '20px',
                                    backgroundColor: '#2A2B2F',
                                    borderRadius: '8px',
                                    marginTop: '10px'
                                }}>
                                    <Video size={24} style={{ marginBottom: '15px', color: '#007bff' }} />
                                    <p style={{ 
                                        color: '#ffffff95', 
                                        textAlign: 'center', 
                                        marginBottom: '15px',
                                        fontSize: '14px'
                                    }}>
                                        This feature is currently in beta testing.
                                    </p>
                                    <button
                                        onClick={() => {
                                            trackEvent('video_upload_beta_interest', {
                                                userName: userInfo.name,
                                            });
                                            setShowBetaMessage(true);
                                        }}
                                        className="gradient-button"
                                    >
                                        <span>Join Beta Program</span>
                                    </button>
                                    {showBetaMessage && (
                                        <p style={{ 
                                            color: '#4CAF50', 
                                            marginTop: '15px',
                                            fontSize: '14px',
                                            textAlign: 'center'
                                        }}>
                                            Thanks for your interest! We'll notify you when the feature is ready.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {uploadError && (
                        <p style={{ color: 'red', marginTop: '10px' }}>{uploadError}</p>
                    )}
                </div>)}

            </div>

            {/* CRM List */}
            <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        //border: '1px solid #dfe1e5',
                        border: '1px solid #222529',
                        backgroundColor: '#27292D',//'white', //Dark mode
                        overflowY: 'auto',
                        marginTop: '30px'
                    }}
                >
                {/* LinkedIn Prospects Section */}
                <div>
                    <h3 style={{color:"white" /*Dark mode*/}}>LinkedIn Prospects</h3>
                    
                    {/* Add new prospect form */}
                    <div className="prospect-input-container" style={{maxWidth: '1400px', justifySelf: 'center'}}>
                        <div className="input-row">
                            <div className="url-input-container" style={{ position: 'relative' }}>
                                {isProcessingLinkedinScreenshot && (
                                    <div style={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '12px',
                                        // transform: 'translateY(-10%)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '6px',
                                        color: '#666',
                                        fontSize: '13px'
                                    }}>
                                        <div className="spinner-border spinner-border-sm" 
                                            style={{ 
                                                width: '10px', 
                                                height: '10px',
                                                borderWidth: '2px'
                                            }} 
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={linkedinUrl}
                                    ref={linkedinUrlInputRef}
                                    onChange={(e) => {
                                        setLinkedinUrl(e.target.value);
                                        // Clear error when user starts typing
                                        if (error) setError(null);
                                    }}
                                    onBlur={() => {
                                        // Validate on blur
                                        const validationError = validateLinkedinUrl(linkedinUrl);
                                        if (validationError) {
                                            setError(validationError);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && linkedinUrl && !isProcessingLinkedinScreenshot) {
                                            const validationError = validateLinkedinUrl(linkedinUrl);
                                            if (validationError) {
                                                setError(validationError);
                                                return;
                                            }
                                            handleProspectSubmission();
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        padding: '10px',
                                        paddingLeft: isProcessingLinkedinScreenshot ? '30px' : '10px',
                                        color: isProcessingLinkedinScreenshot ? '#666' : 'inherit',
                                        backgroundColor: isProcessingLinkedinScreenshot ? '#f8f9fa' : 'white',
                                        transition: 'all 0.2s ease',
                                        borderRadius: '5px',
                                        border: error ? '1px solid #dc3545' : '1px solid #dfe1e5',
                                        fontSize: '13px',
                                        flex: 1
                                    }}
                                    placeholder="https://www.linkedin.com/in/username (⌘/Ctrl + L)"
                                    // className={`url-input ${error ? 'error' : ''}`}
                                    disabled={isProcessingLinkedinScreenshot}
                                />
                                {error && (
                                    <small 
                                        id="url-error" 
                                        className="error-message"
                                        style={{ 
                                            color: '#dc3545',
                                            fontSize: '12px',
                                            marginTop: '4px',
                                            display: 'block'
                                        }}
                                    >
                                        {error}
                                    </small>
                                )}
                            </div>

                            <button 
                                onClick={handleProspectSubmission}
                                disabled={(!linkedinUrl) || isProcessingLinkedinScreenshot}
                                className="main-button"
                                style={{ 
                                    display: 'flex',
                                    // flex: 1,
                                    backgroundColor: (isProcessingLinkedinScreenshot) ? '#cccccc' : '#e1f679',
                                    color: (isProcessingLinkedinScreenshot) ? '#666666' : 'black',
                                    cursor: (isProcessingLinkedinScreenshot) ? 'not-allowed' : 'pointer',
                                    opacity: (isProcessingLinkedinScreenshot) ? 0.7 : 1,
                                    alignSelf: 'flex-start',
                                    width: '200px',
                                    // ... other styles
                                }}
                            >
                                {isProcessingLinkedinScreenshot ? (
                                    <>
                                        <span className="spinner-border-large" role="status" style={{marginRight:'5px'}}/>
                                        Adding...
                                    </>
                                ) : (
                                    'Add Prospect'
                                )}
                            </button>
                        </div>

                        {/* {error && <div className="error-message">{error}</div>} */}
                    </div>

                    {/* Prospects table */}
                    <div style={{ overflowX: 'auto' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        #
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <User size={16} />
                                        Name
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Briefcase size={16} />
                                        Company
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Award size={16} />
                                        Job Title
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Activity size={16} />
                                        Status
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <MessageSquare size={16} />
                                        Custom Pitch
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Film size={16} />
                                        Video
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        {/* <Eye size={16} />
                                        Views */}
                                        <Download size={16} />
                                        Download
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5', color:"white" /*#Darkmode*/ }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Settings size={16} />
                                        Actions
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                {prospects.map((prospect, index) => (
                                    <tr
                                        key={prospect._id}
                                        // style={{
                                        //     backgroundColor: processingUrls.has(prospect.linkedinUrl) 
                                        //     ? '#f8f9fa' 
                                        //     : 'white'
                                        // }}
                                    >
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43', color: '#ffffff95', fontSize:'14px'}}>
                                            {prospects.length - index}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43', maxWidth: '150px' }}>
                                            <a 
                                                href={prospect.linkedinUrl} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                style={{ color: '#4890F4', fontSize:'14px', fontWeight:'bold', maxWidth: '150px'}}
                                            >
                                                {prospect.firstName} {prospect.lastName}<ExternalLink size={14} style={{ marginLeft: '4px' }} />
                                            </a>
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43' , color:'white', fontSize:'14px', maxWidth: '150px'}}>
                                            {prospect.company}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43' , color:'white', fontSize:'14px', maxWidth: '150px'}}>
                                            {prospect.jobTitle}
                                        </td>
                                        <td style={{ 
                                            padding: '12px', 
                                            borderBottom: '1px solid #3B3C43',
                                            fontSize: '14px'
                                        }}>
                                            <select
                                                value={prospect.status || 'new'}
                                                onChange={(e) => handleStatusChange(prospect._id, e.target.value)}
                                                style={{
                                                    padding: '4px 8px',
                                                    paddingRight: '24px',
                                                    borderRadius: '4px',
                                                    border: '2px solid #dfe1e5',
                                                    fontSize: '13px',
                                                    backgroundColor: '#282828',
                                                    cursor: 'pointer',
                                                    // Color coding based on status
                                                    color: {
                                                        new: 'white',
                                                        video_sent: '#2196F3',
                                                        viewed: '#4CAF50',
                                                        responded: '#9C27B0',
                                                        meeting_scheduled: '#FF9800',
                                                        negotiating: '#E91E63',
                                                        deal_closed: '#4CAF50',
                                                        lost: '#F44336'
                                                    }[prospect.status || 'new']
                                                }}
                                            >
                                                {PROSPECT_STATUSES.map(status => (
                                                    <option 
                                                        key={status.value} 
                                                        value={status.value}
                                                    >
                                                        {status.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {index === 0 && (
                                                <div style={{ 
                                                    fontSize: '11px', 
                                                    color: '#666', 
                                                    marginTop: '4px',
                                                    fontStyle: 'italic' 
                                                }}>
                                                    ⌘/Ctrl + S: Mark as sent
                                                </div>
                                            )}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43' , color:'white', fontSize:'14px'}}>
                                            <div style={{ position: 'relative' }}>
                                                <textarea
                                                    value={prospect.customPitch || ''}
                                                    onChange={(e) => handlePitchChange(prospect._id, e.target.value)}
                                                    onKeyDown={(e) => {
                                                        const isCmdOrCtrl = e.metaKey || e.ctrlKey;
                                                        
                                                        if (isCmdOrCtrl) {
                                                            if (e.key === '7') {
                                                                e.preventDefault();
                                                                handleGenerateSpeech(prospect._id, prospect.customPitch);
                                                            } else if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                handleGenerateSpeech(prospect._id, prospect.customPitch);
                                                            }
                                                        }
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        minHeight: '80px',
                                                        padding: '8px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #dfe1e5',
                                                        marginBottom: '5px',
                                                        fontFamily: 'inherit'
                                                    }}
                                                    disabled={generatingPitch.has(prospect._id)}
                                                    placeholder="Enter custom pitch or generate one..."
                                                />


                                                <div style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    marginBottom: '5px'
                                                }}>
                                                    {/* Save indicator */}
                                                    <span style={{ 
                                                        fontSize: '0.8em', 
                                                        color: '#666',
                                                        fontStyle: 'italic',
                                                        width: '100px'
                                                    }}>
                                                        {savingPitch.has(prospect._id) ? 'Saving...' : (
                                                            <>
                                                                Saved <span style={{ color: '#4CAF50' }}>✓</span>
                                                            </>
                                                        )}
                                                    </span>
                                                    
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        {/* Generate pitch button */}

                                                        <div>
                                                            <select
                                                                value={selectedLanguages[prospect._id] || 'en'}
                                                                onChange={(e) => setSelectedLanguages(prev => ({
                                                                    ...prev,
                                                                    [prospect._id]: e.target.value
                                                                }))}
                                                                style={{
                                                                    padding: '2px 8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #dfe1e5',
                                                                    fontSize: '0.8em',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <option value="en">🇬🇧 English</option>
                                                                <option value="fr">🇫🇷 French</option>
                                                                <option value="es">🇪🇸 Spanish</option>
                                                                <option value="de">🇩🇪 German</option>
                                                                <option value="it">🇮🇹 Italian</option>
                                                            </select>
                                                            {index === 0 && (
                                                                <div style={{ 
                                                                    fontSize: '11px', 
                                                                    color: '#666', 
                                                                    marginTop: '4px',
                                                                    fontStyle: 'italic' 
                                                                }}>
                                                                    Pitch language
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div>
                                                            <button
                                                                className="pink-button"
                                                                onClick={() => handleGeneratePitch(prospect._id, selectedLanguages[prospect._id])}
                                                                disabled={generatingPitch.has(prospect._id)}
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    backgroundColor: '#AB5898',
                                                                    color: 'white',
                                                                    border: 'none',
                                                                    borderRadius: '4px',
                                                                    cursor: generatingPitch.has(prospect._id) ? 'not-allowed' : 'pointer',
                                                                    fontSize: '0.8em',
                                                                    width: '130px',
                                                                    // height: '40px'
                                                                }}
                                                            >
                                                                {generatingPitch.has(prospect._id) ? (
                                                                    <>
                                                                        <span className="spinner-border spinner-border-sm" />
                                                                        {' '}Generating...
                                                                    </>
                                                                ) : prospect.customPitch ? (
                                                                    'Regenerate Pitch'  // Si un pitch existe déjà
                                                                ) : (
                                                                    'Generate Pitch'    // Si pas encore de pitch
                                                                )}
                                                            </button>
                                                            {index === 0 && (
                                                                <div style={{ 
                                                                    fontSize: '11px', 
                                                                    color: '#666', 
                                                                    marginTop: '4px',
                                                                    fontStyle: 'italic',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    ⌘/Ctrl + 6
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/* Text-to-Speech button */}
                                                        <div>
                                                            <button
                                                                className="purple-button"
                                                                onClick={() => handleGenerateSpeech(prospect._id, prospect.customPitch)}
                                                                disabled={generatingSpeech.has(prospect._id) || !prospect.customPitch}
                                                                style={{
                                                                    padding: '4px 8px',
                                                                    backgroundColor: '#5C4BD3',
                                                                    color: 'white',
                                                                    border: 'none',
                                                                    borderRadius: '4px',
                                                                    cursor: generatingSpeech.has(prospect._id) ? 'not-allowed' : 'pointer',
                                                                    fontSize: '0.8em',
                                                                    width: '130px',
                                                                    // height: '40px'
                                                                }}
                                                            >
                                                                {generatingSpeech.has(prospect._id) ? (
                                                                    <>
                                                                        <span className="spinner-border spinner-border-sm" />
                                                                        {' '}Converting...
                                                                    </>
                                                                ) : audioUrls[prospect._id] ? (
                                                                    'Regenerate Speech'  // Si l'audio existe déjà
                                                                ) : (
                                                                    'Generate Speech'    // Si pas encore d'audio
                                                                )}
                                                            </button>
                                                            {index === 0 && (
                                                                <div style={{ 
                                                                    fontSize: '11px', 
                                                                    color: '#666', 
                                                                    marginTop: '4px',
                                                                    fontStyle: 'italic',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    ⌘/Ctrl + 7
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Audio player */}
                                                    {audioUrls[prospect._id] && (
                                                        <audio
                                                            controls
                                                            key={audioUrls[prospect._id]} // Add key to force re-render
                                                            src={audioUrls[prospect._id]}
                                                            style={{ width: '100%', marginTop: '10px', height:'25px'}}
                                                        >
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    )}
                                            </div>
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43' }}>
                                            {/*</td>{prospect.videoData?.pageUrl ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                    <div style={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: '8px',
                                                        background: '#f8f9fa',
                                                        padding: '8px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #dfe1e5'
                                                    }}>
                                                        <a 
                                                            href={prospect.videoData.pageUrl} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: '#1a73e8',
                                                                textDecoration: 'none',
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            View Landing Page
                                                        </a>
                                                        <button
                                                            onClick={() => handleCopyLink(prospect._id, prospect.videoData.pageUrl)}
                                                            style={{
                                                                padding: '4px 8px',
                                                                backgroundColor: copiedLinks.has(prospect._id) ? '#34a853' : '#1a73e8',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                                fontSize: '0.8em',
                                                                minWidth: '80px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                gap: '4px'
                                                            }}
                                                        >
                                                            {copiedLinks.has(prospect._id) ? (
                                                                <>
                                                                    <span style={{ fontSize: '1.2em' }}>✓</span> 
                                                                    Copied!
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <svg 
                                                                        width="14" 
                                                                        height="14" 
                                                                        viewBox="0 0 24 24" 
                                                                        fill="currentColor"
                                                                        style={{ marginRight: '4px' }}
                                                                    >
                                                                        <path d="M16 1H4C2.9 1 2 1.9 2 3v14h2V3h12V1zm3 4H8C6.9 5 6 5.9 6 7v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                                                    </svg>
                                                                    Copy Link
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div style={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: '8px',
                                                        fontSize: '0.9em', 
                                                        color: '#666'
                                                    }}>
                                                        <svg 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 24 24" 
                                                            fill="currentColor"
                                                        >
                                                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                                        </svg>
                                                        Views: {prospect.videoData.viewCount}
                                                    </div>
                                                </div>
                                            ) : (*/}
                                                <div>
                                                    <button
                                                        onClick={() => handleGenerateVideo(prospect._id)}
                                                        // onClick={() => {
                                                        //     alert('hey there, Boukar here, this feature is being updated, please try again tomorrow');
                                                        // }}

                                                        disabled={
                                                        generatingVideo.has(prospect._id) ||
                                                        !prospect.customPitch || 
                                                        !audioUrls[prospect._id]
                                                        }
                                                        className="main-button"
                                                        style={{
                                                            padding: '8px 16px',
                                                            backgroundColor: '#e1f679',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            cursor: (generatingVideo.has(prospect._id) ||
                                                                    !prospect.customPitch || 
                                                                    !audioUrls[prospect._id]) ? 'not-allowed' : 'pointer',
                                                            width: '100%',
                                                            minWidth: '150px',
                                                            height: '35px',
                                                            opacity: generatingVideo.has(prospect._id) ? 0.7 : 1
                                                        }}
                                                    >
                                                        {generatingVideo.has(prospect._id) ? (
                                                            <>
                                                                <span className="spinner-border" style={{marginRight:'5px'}}/>
                                                                Generating...
                                                            </>
                                                        ) : prospect.videoData?.videoUrl ? (
                                                            'Regenerate Video'  // Si la vidéo existe déjà
                                                        ) : (
                                                            'Generate Video'    // Si pas encore de vidéo
                                                        )}
                                                    </button>
                                                    {index === 0 && (
                                                            <div style={{ 
                                                                fontSize: '11px', 
                                                                color: '#666', 
                                                                marginTop: '4px',
                                                                fontStyle: 'italic',
                                                                textAlign: 'center'
                                                            }}>
                                                                ⌘/Ctrl + 8
                                                            </div>
                                                        )}
                                                    {(!prospect.customPitch || !audioUrls[prospect._id]) && (
                                                        <div style={{ 
                                                            fontSize: '0.8em', 
                                                            color: '#666', 
                                                            marginTop: '5px',
                                                            textAlign: 'center' 
                                                        }}>
                                                            Missing required elements:
                                                            <ul style={{ margin: '5px 0', paddingLeft: '20px', textAlign: 'left' }}>
                                                                {!prospect.customPitch && <li>Generated pitch</li>}
                                                                {!audioUrls[prospect._id] && <li>Generated speech</li>}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            {/*)}*/}
                                        </td>
                                        {/* <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43', textAlign: 'center' , color:'white', fontSize:'14px'}}>
                                            {prospect.videoData?.viewCount || 0}
                                        </td> */}
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43', textAlign: 'center' , color:'#666', fontSize:'14px'}}>
                                            {prospect.videoData?.videoUrl && (
                                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                    <video
                                                        key={prospect.videoData.videoUrl}
                                                        controls 
                                                        width="250"
                                                        preload="metadata"
                                                        onLoadedMetadata={(e) => {
                                                            // Set preview to the middle of the video
                                                            e.target.currentTime = e.target.duration / 2;
                                                        }}
                                                        style={{ 
                                                            marginTop: '10px',
                                                            borderRadius: '8px',
                                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                                        }}
                                                    >
                                                        <source 
                                                            src={prospect.videoData.videoUrl} 
                                                            type="video/mp4"
                                                        />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    
                                                    <button
                                                        onClick={() => handleDownloadVideo(
                                                            prospect.videoData.videoUrl,
                                                            `${prospect.firstName}_${prospect.lastName}`
                                                        )}
                                                        className="gradient-button"
                                                        style={{
                                                            marginTop: '10px',
                                                            padding: '8px 16px',
                                                            backgroundColor: '#5C4BD3',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            alignSelf: 'center',
                                                            gap: '8px',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        <Download size={12} /> Download Video
                                                    </button>
                                                    {index === 0 && (
                                                        <div style={{ 
                                                            fontSize: '11px', 
                                                            color: '#666', 
                                                            marginTop: '4px',
                                                            fontStyle: 'italic',
                                                            textAlign: 'center'
                                                        }}>
                                                            ⌘/Ctrl + 9
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {!prospect.videoData?.videoUrl && "Generate a video to download it"}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #3B3C43' }}>
                                            <button
                                                className="red-delete-button"
                                                onClick={() => handleDeleteProspect(prospect._id)}
                                                disabled={isDeleting.has(prospect._id)}
                                                style={{
                                                    padding: '4px 8px',
                                                    backgroundColor: isDeleting.has(prospect._id) ? '#dc354580' : '#dc3545',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    cursor: isDeleting.has(prospect._id) ? 'not-allowed' : 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                    width: '100px',
                                                    height: '40px'
                                                }}
                                            >
                                                {isDeleting.has(prospect._id) ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Deleting...
                                                    </>
                                                ) : (
                                                    'Delete Prospect'
                                                )}
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                                {/* Empty state message */}
                                {prospects.length === 0 && (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center', padding: '40px', color: '#666' }}>
                                            No prospects added yet. Paste a LinkedIn URL above to get started.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Year Progress Bar */}
        <div style={{
            padding: '20px',
            marginTop: '30px',
            marginBottom: '30px',
            borderTop: '1px solid #222529',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px',
                color: '#ffffff95',
                fontSize: '14px'
            }}>
                <span>Year {new Date().getFullYear()} progression (reminder to do the most)</span>
                <span>{calculateYearProgress()}% complete</span>
            </div>
            <div style={{
                width: '100%',
                height: '4px',
                backgroundColor: '#2A2B2F',
                borderRadius: '2px',
                overflow: 'hidden'
            }}>
                <div style={{
                    width: `${calculateYearProgress()}%`,
                    height: '100%',
                    backgroundColor: '#4CAF50',
                    borderRadius: '2px',
                    transition: 'width 0.3s ease'
                }}/>
            </div>
        </div>
        {showSubscriptionModal && <SubscriptionModal />}
        {/* <ShortcutHelp /> */}

            {/* Feedback Button */}
            <FeedbackButton username={userName} />
        </div>
    );
}

export default Dashboard;